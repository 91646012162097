/* ---------------------------------------------------
   Alerts
----------------------------------------------------- */

.alert {
  border-radius: ix-prop('br');
  line-height: $line-height-regular;
  min-height: $button-height;
  padding: $button-filter-pad-top $button-pad-top;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  align-items: center;

  a {
    @extend .underline;
  }

  .paragraph {
    @extend .d-block;
    margin: $gutter-mini 0;
  }

  &.--small {
    font-size: $font-size-12;
  }

  &.--scroll {
    max-height: 120px;
    overflow-x: hidden;
    overflow-y: scroll;

    // show scrollbars //
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      border: solid 3px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px ix-color('gray-500');
      border: solid 3px transparent;
      border-radius: 6px;
    }
  }

  &.--list {
    // Dots //
    ul {
      @extend .font-regular;
      list-style-type: disc;
      margin-top: calc($gutter/2);
      text-align: left;

      li {
        margin: $gutter-mini calc($gutter/2);
      }
    }

    &.alert-error {
      p {
        @extend .font-regular;
        @extend .color-error;
        margin-top: calc($gutter/2);
        text-align: center;
      }
    }
  }

  // Alert with Icon //
  &.--icon {
    padding: $button-pad-top $button-pad-top $button-pad-top
      calc($button-pad-side * 2);
    text-align: left;

    .icon {
      position: absolute;
      height: auto;
      left: $gutter-sm;
    }

    &.--small {
      padding: calc($gutter/2) calc($gutter/2) calc($gutter/2) 35px;
      min-height: auto;

      .icon {
        position: absolute;
        height: auto;
        top: 13px;
        left: 12px;
      }
    }
  }

  // Header with Icon //
  .header-icon {
    .icon {
      position: static;
      height: auto;
      margin-right: $gutter-mini;
    }
  }

  &.--img {
    position: relative;
    overflow: hidden;
    padding-right: 30%;

    .img {
      position: absolute;
      right: $gutter;
      top: 0;
      max-width: 30%;
    }
  }

  &.text-align-left {
    text-align: left;
  }

  .status-info {
    font-size: $font-size-12;

    .icon {
      font-size: $font-size-14;
    }
  }

  .button {
    &.--small {
      font-size: $font-size-10;
      padding: 0 $gutter-mini;
    }
  }

  &.alert-warning {
    .button {
      @extend .alert-warning;
      border: 1px solid ix-color('yellow-200');
      color: ix-color('yellow-200');

      &:hover:not([disabled]) {
        background-color: rgba(129, 85, 14, 0.1);
        color: ix-color('yellow-200');
      }
    }
    .fa-triangle-exclamation {
      display: inline-block;
    }

    .fa-check {
      display: none;
    }
  }

  &.alert-active {
    border: 1px solid $color-brand-primary;

    .icon {
      color: $color-brand-primary;
    }
    .button {
      @extend .button-secondary;
    }

    .fa-check {
      display: inline-block;
    }
    .fa-triangle-exclamation {
      display: none;
    }
  }
}

.input-error {
  @extend .bold;
  color: ix-color('red-200');
  margin: $gutter-mini 0;
  font-size: $font-size-12;

  .icon {
    margin-right: $gutter-mini;
  }
}
