.block-grid {
  .grid-block {
    margin: $gutter-sm 0;

    &.two-cols-container {
      .col-md-6 {
        @extend .container-bg-white;
        padding: $gutter-sm;
        max-width: calc(50% - #{$gutter-sm}/ 2);
        margin-right: calc($gutter-sm/2);
        margin-left: calc($gutter-sm/2);

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
          margin-right: 0 !important;
          margin-left: 0 !important;
          max-width: 100%;
          margin-bottom: $gutter-sm;
        }
      }

      .title {
        @extend .bold;
        margin-bottom: calc($gutter/2);
      }

      .value {
        @extend .bold;
        font-size: $font-size-20;
        margin-bottom: $gutter-mini;

        .value-description {
          margin: 2px;
          @extend .font-regular;
          color: $color-txt-sec;
          font-size: $font-size-12;
        }
      }

      .value-secondary {
        .value-description {
          margin: 2px;
          font-size: $font-size-10;
          color: $color-txt-sec;
        }
      }

      .small-card-wrapper {
        .small-card-container {
          padding: $gutter-sm;
          margin: calc($gutter-sm/2) 0 calc($gutter-sm/2) 0;

          @include media-breakpoint-up(md) {
            margin: 0 calc($gutter-sm/2) 0 calc($gutter-sm/2);
          }
        }

        &:first-child {
          .small-card-container {
            margin-left: 0;
          }
        }

        &:last-child {
          .small-card-container {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    &.one-row-container {
      @extend .container-bg-white;
      padding: $gutter-sm 0;

      .row-block-container {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        .row-block {
          &:not(:last-child) {
            margin-right: 40px;
          }

          @include media-breakpoint-down(sm) {
            padding: 10px 0;
          }
        }
      }
    }

    &.multiple-content-container {
      @extend .container-bg-white;
      padding: $gutter-sm;

      .text-header {
        margin-bottom: 10px;
      }

      .sep-light {
        margin: 20px $gutter-sm;
        width: 95%;
      }
    }

    .list-of-data {
      @extend .bold;

      li {
        margin: 10px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .label {
        @extend .font-regular;
        margin-right: calc($gutter/2);
        color: $color-txt-sec-no-focus;
      }
    }
  }

  &.white-grid {
    .two-cols-container {
      [class*='col-'] {
        @extend .container-bg-white;
      }
    }

    .one-row-container {
      @extend .container-bg-white;
    }

    .multiple-content-container {
      @extend .container-bg-white;
    }
  }
}
