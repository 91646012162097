/* -----------------------------------
    Text editor bar
-------------------------------------*/

.text-editor-bar {
  width: 100%;
  display: flex;

  .apply-style {
    background: ix-color('gray-200');
    border-radius: ix-prop('br') 0 0 ix-prop('br');
    margin-right: 1px;
    max-width: $button-height;

    &:hover {
      background: ix-color('gray-300');
    }

    &:nth-child(2) {
      border-radius: 0;
    }
  }

  .dropdown-container {
    align-items: center;
    background: ix-color('gray-200');
    border-radius: 0 ix-prop('br') ix-prop('br') 0;
    display: flex;
    flex-grow: 1;
    min-height: 100%;
    padding: 0 $gutter;

    .label {
      display: block;
    }

    .content-to-hide {
      @extend .box-shadow-dropdown-top;
      bottom: 24px;

      // Font size //
      .font-size-small {
        font-size: $font-size-10;
      }

      .font-size-normal {
        font-size: $font-size-14;
      }

      .font-size-large {
        font-size: $font-size-20;
      }

      .font-size-huge {
        font-size: $font-size-28;
      }
    }
  }

  .button-active {
    background: ix-color('gray-300');
  }
}

/* -----------------------------------
    Status Bar
-------------------------------------*/

.status-bar {
  @extend .bg-ix-white;
  @extend .box-shadow-focus;
  align-items: center;
  padding: $gutter-sm $gutter;
  min-width: 100%;
  margin: 1px -#{$gutter * 2};

  @include media-breakpoint-down(xs) {
    margin: 1px -#{$gutter};
  }

  .docs-num-container {
    @extend .bold;
    align-items: center;
    display: flex;
    font-size: $font-size-12;
    padding-right: $gutter-lg;

    .icon {
      margin-right: $gutter-mini;
      font-size: $font-size-20;
    }

    .num {
      padding-right: $gutter-mini;
    }

    // arrow effect //
    &:after {
      @include media-breakpoint-up(lg) {
        content: '';
        border: 1px solid ix-color('gray-200');
        width: 44px;
        height: 38px;
        position: absolute;
        right: 0;
        border-left: 0;
        border-bottom: 0;
        transform: rotate(60deg) skewX(30deg);
      }
    }
  }

  .step-container {
    margin-right: calc($gutter/2);
    padding: 2px 0;
    font-size: $font-size-12;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    .button {
      margin: 0;
      margin-left: calc($gutter/2);

      &.--arrow-right {
        padding-right: $gutter * 3;
      }

      &.--hide-hover {
        .label {
          left: $gutter;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .buttons-container {
      flex: 0 0 100%;
      max-width: 100%;
      padding: $gutter 0 0 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .buttons-container {
      flex-direction: column;
      padding: $gutter;

      .button {
        &.--arrow-right {
          display: block;
          margin: 0;
          width: 100%;
        }
        &.--hide-hover {
          margin: 0 0 $gutter 0;
        }
      }
    }
    .docs-num-container {
      padding: $gutter;
    }
  }

  @include media-breakpoint-down(xs) {
    .steps-container {
      padding-top: $gutter;
    }
  }
}

/* -----------------------------------
    Bar Fixed at the bottom
-------------------------------------*/

.fixed-bottom-bar {
  background-color: $color-bg-toolbar;
  bottom: 0;
  left: 0;
  min-width: 100%;
  padding: $gutter;
  position: fixed;
  z-index: $zindex-toolbar;
  display: flex;
  text-align: center;

  .button {
    min-width: 150px;
  }

  .button-primary {
    margin-right: $gutter-sm;
  }

  .container {
    .row {
      .--flex {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .col-sm-9 {
    padding-right: calc($gutter/2);
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      text-align: center;
      padding: 0;

      .button {
        width: 100%;
      }

      .notification-message {
        justify-content: center;
        margin-bottom: $gutter;
      }
    }

    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  .notification-message {
    display: flex;
    @extend .bold;
    color: ix-color('ix-white');
    margin-right: $gutter-lg;
    align-items: center;

    .icon {
      margin-right: calc($gutter/2);
      height: 100%;
    }

    .notitification-text {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
