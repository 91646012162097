/* -----------------------------------
    Summary Document Number
-------------------------------------*/

.summary-title {
  color: $color-txt-sec-focus;
  font-size: $font-size-20;
  margin-bottom: $gutter-sm;

  span {
    margin-right: $gutter-mini;
  }

  .icon {
    margin-left: $gutter-mini;
  }
}

/* -----------------------------------
   Mobile Side Content Header
-------------------------------------*/

.mobile-side-header {
  display: flex;
  align-items: center;
  background-color: $color-brand-primary;
  margin-bottom: $gutter;
  min-height: $mobile-header-height;
  // padding: $button-pad-top;
  position: relative;
  color: $color-txt-white;

  .header-text {
    @extend .bold;
    color: $color-txt-white;
    font-size: $font-size-16;
    margin: 0;
    padding-left: calc($gutter/2);
  }

  .close-button {
    background: $color-brand-primary;
    border: none;
    border-left: 1px solid ix-color('white');
    color: $color-txt-white;
    height: 100%;
    padding: $button-pad-top;
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      color: ix-color('white');
      font-size: $font-size-20;
    }

    &:after {
      background-color: rgba(255, 255, 255, 0.2);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

/* -----------------------------------
    Open Account Header
-------------------------------------*/

.header-open-account-container {
  margin: $gutter auto;
  padding: 0;

  .header-open-account-logo {
    .logo-container {
      @extend .bg-ix-white;
      border-radius: ix-prop('br', 'large');
      padding: calc($gutter/2);
      width: fit-content;

      .img {
        height: auto;
        max-height: 80px;
        max-width: 100%;
      }

      .account-name {
        @extend .bold;
        color: $color-brand-primary;
        font-size: $font-size-34;
        font-size: 2vw;
        padding: $gutter-mini;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        margin: 0 auto;

        .account-name {
          font-size: $font-size-28;
        }
      }
    }
  }

  .header-open-account-client {
    .client-info {
      font-size: $font-size-22;
      color: ix-color('gray-500');
      margin: $gutter 0;
      text-align: right;

      span {
        color: $color-txt-sec-focus;
      }

      @include media-breakpoint-down(md) {
        font-size: $font-size-22;
        text-align: center;

        span {
          display: block;
        }
      }
    }
  }
}
