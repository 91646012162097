.list-of-cards {
  @extend .d-table;
  border-collapse: collapse;

  li {
    display: contents;
  }
}

.card {
  @include transition(0.2s);
  cursor: pointer !important;
  display: table-row;
  border-bottom: 2px solid $color-bg-ix;
  background: ix-color('ix-white');

  // Interaction //
  &:hover {
    border-bottom-color: $color-brand-primary;
    background-color: transparent;

    .client .type {
      padding-left: $gutter-mini;
    }
  }
  // Archived //
  &.archived {
    .status-label,
    .type,
    .client,
    .date,
    .card-label,
    .value-text,
    .icon {
      opacity: 0.4;
    }
    .sequence-number {
      .sequence-number-text {
        opacity: 0.4;
      }
    }
  }

  // Selected //
  &.selected-card {
    background-color: $color-bg-selected;

    @include media-breakpoint-up(md) {
      .sequence-number-text:hover:after {
        background: $color-bg-selected;
      }
    }
    @include media-breakpoint-only(lg) {
      .status {
        .status-label:hover {
          background: $color-bg-selected;
        }
      }
    }
  }

  // Border Radiuses //
  &:first-child {
    .select-checkbox,
    .status {
      border-radius: ix-prop('br') 0 0 0;
    }

    .action,
    .date-cr {
      border-radius: 0 ix-prop('br') 0 0;
    }
  }

  &:last-child {
    .select-checkbox,
    .status {
      border-radius: 0 0 0 ix-prop('br');
    }

    .action,
    .date-cr {
      border-radius: 0 0 ix-prop('br') 0;
    }
  }

  // Edge: //
  a {
    @include media-breakpoint-up(md) {
      padding: 5px 0;
    }
  }
  a,
  .mobile-right,
  .mobile-left,
  .dates {
    align-items: center;
    display: flex;
    width: 100%;

    @include media-breakpoint-down(sm) {
      align-items: unset;
    }
  }

  .mobile-right {
    width: 80%;
  }

  .value {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  .date {
    width: 80px;
  }

  // Display: contents //
  @supports (display: contents) {
    a,
    .mobile-right,
    .mobile-left,
    .dates,
    .date {
      align-items: unset;
      display: contents;
      width: auto;
    }
    .value {
      width: auto;
    }
  }

  // Cell Content //
  .cell {
    @include transition(0.2s);
    display: table-cell;
    padding: $gutter-sm $gutter-sm $gutter-sm 0;
    vertical-align: top;

    &.checkbox-cell {
      vertical-align: middle;
    }
  }

  & > .cell:first-child {
    padding-left: $gutter-sm;
  }

  & > div:first-child > .cell:first-child,
  & > a:first-child > div:first-child > .cell:first-child {
    padding-left: $gutter-sm;
  }

  .checkbox {
    padding-left: $gutter-sm;
  }

  .sequence-number-text {
    color: $color-txt-sec-focus;
    font-size: $font-size-12;
    max-width: $sequence-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      max-width: $sequence-width-sm;
    }

    @include media-breakpoint-up(md) {
      &:hover {
        color: $color-bg-ix;

        &:after {
          background: $color-bg-ix;
          border-radius: ix-prop('br');
          color: $color-txt-sec-focus;
          content: attr(data-text);
          display: block;
          margin-top: -#{$gutter-sm * 2};
          overflow: visible;
          padding: $gutter-sm calc($gutter/2) $gutter-sm 0;
          position: absolute;
          text-overflow: inherit;
          white-space: normal;
          width: auto;
          word-wrap: break-word;
        }
      }
    }
  }

  &:not(.--simple) {
    .status {
      padding-left: 0;
    }
  }

  .action {
    width: 1%;
  }

  // Typography //

  .type {
    @extend .text-label;
    @extend .--small;
    text-transform: uppercase;
    min-width: max-content;
    display: inline-block;
  }

  .client {
    @extend .bold;
    @extend .d-block;
    font-size: $font-size-14;
    @include media-breakpoint-up(md) {
      max-width: 40ch;
      min-width: 16ch;
    }
  }

  &:not(.--simple) {
    .client {
      padding-top: $gutter-mini;
    }
  }

  .archived-title {
    @extend .text-label;
    @extend .--small;
    border-left: 1px solid $color-borders;
    display: inline-block;
    margin-left: $gutter-mini;
    padding-left: $gutter-mini;
    text-transform: uppercase;
  }

  .value-text {
    @extend .text-header;
    @extend .h4;
    @extend .text-align-right;
  }

  .type-client {
    .client {
      word-break: break-word;
    }
  }

  .card-label {
    @extend .text-label;
    @extend .--small;
    white-space: nowrap;
  }

  .contact-card-label {
    @extend .card-label;
    display: none !important;
  }

  .contact-cell-balance {
    align-self: auto !important;
  }

  .--align-center {
    align-self: auto !important;
  }

  .contact-cell {
    display: none !important;
  }

  .contact-cell-mobile {
    // display: block !important;
    // align-self: center;
  }

  .card-badge {
    @extend .bold;
    @extend .uppercase;
    font-size: $font-size-10;
    color: $color-brand-hover;
    padding: 2px 8px;
    border: 1px solid #139062;
    border-radius: 10px;
    margin: $gutter-mini;

    &:first-child {
      margin: $gutter-mini $gutter-mini $gutter-mini 0px;
    }

    &.gray {
      color: $color-txt-sec-no-focus;
      border: 1px solid $color-borders;
    }
  }

  .date {
    color: $color-txt-sec-focus;
    font-size: $font-size-12;
    width: max-content;
  }

  .date-cr,
  .date-ex {
    .icon {
      color: $color-txt-sec;
      display: none;
    }

    &.expired {
      .date {
        @extend .status-color-canceled;
      }

      .card-label {
        @extend .status-color-canceled;
      }

      .icon {
        @extend .status-color-canceled;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.--simple {
      .date-cr {
        &:last-child {
          width: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.--simple {
      .text-description {
        color: $color-txt-primary;
        max-height: 85px;
        max-width: 45ch;
        overflow: hidden;
        word-break: break-word;
      }

      .--observations {
        width: $gutter-lg * 10;
      }
    }
  }

  &.--contacts {
    background-color: ix-color('gray-200');
  }
}

.card {
  &.--sequence {
    cursor: pointer;
    position: relative;

    .ix-loading {
      height: 95%;
    }

    .card-badge {
      margin: calc($gutter/2);
    }
  }
}

/**************************** MOBILE ********************************/
@include media-breakpoint-down(sm) {
  .card {
    display: block;
    background-color: ix-color('ix-white');
    transition: none;

    &.--manage-user-cell {
      .cell {
        width: 100% !important;
      }

      .mobile-right {
        justify-content: end !important;
        max-width: 100% !important;
      }

      .mobile-left {
        max-width: 100% !important;
      }
    }

    &.--sequence {
      position: relative;

      .mobile-left {
        max-width: 85%;

        .card-badge {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .mobile-right {
        max-width: 90%;
        display: flex;
        flex-direction: row;

        .text-align-right {
          text-align: left;
        }

        .action {
          position: absolute;
          right: $gutter-sm * 2;
          top: 50%;
          transform: translate(-50%, -50%);

          .vertical-sep {
            border: none;
          }
        }
      }
    }

    &.--simple {
      .dates {
        flex-direction: column;
        align-self: flex-end;
        .cell {
          align-self: end;
        }
      }
    }

    // Document information //
    a {
      display: flex;
      flex-wrap: wrap;
      padding: $gutter-sm;
      width: 100%;

      .cell {
        background: transparent;
        display: flex;
        padding: $gutter-mini 0;
      }
    }

    // Checkbox //
    .select-checkbox {
      background: transparent;
      padding: 18px $gutter-sm;
      position: absolute;
    }

    // Client and doc type //
    .type-client {
      flex-direction: column;
      word-break: break-word;

      .archived-title {
        border-right: none;
        margin-bottom: $gutter-mini;
        display: inline-block;
      }

      .type {
        padding: 0;
      }

      .client {
        padding: $gutter-mini 0 0 0;
      }
    }

    .text-description {
      overflow: hidden;
      word-break: break-word;
      max-height: 85px;
    }

    // Status Label //
    &:not(.--simple) {
      .status {
        padding-right: calc($gutter/2);
        margin-left: calc(#{$checkbox-size} + calc(#{$gutter}/ 2));
      }
    }

    // Dates //
    .dates {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .date-cr,
    .date-ex {
      margin-left: $gutter-mini;

      .icon {
        display: block;
        margin-left: $gutter-mini;
        font-size: $font-size-12;
      }
      .date {
        font-size: $font-size-10;
      }
    }

    // Title //
    .card-label {
      display: none !important;
    }

    // Document number //
    .sequence-number {
      order: 0;

      .sequence-number-text {
        font-size: $font-size-10;
        max-width: 100%;
        letter-spacing: 0.3px;
      }
    }

    // Document value //
    .value {
      display: flex;
      justify-content: flex-end;
    }

    // Mobile col //
    .mobile-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;
      justify-content: space-between;
      max-width: 50%;
    }

    .mobile-left {
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .contact-card-label {
      display: flex !important;
    }

    .contact-cell-mobile {
      display: none !important;
    }

    .contact-cell-balance {
      align-self: center !important;
    }

    .--align-center {
      align-self: center !important;
    }

    .contact-cell {
      display: block !important;
    }

    &.--contacts {
      display: flex;
      justify-content: space-around;
      div:nth-child(2),
      div:nth-child(3) {
        display: none;
      }
    }

    &.--contacts-cell {
      .cell {
        align-self: start;
        display: block;
      }
    }
  }
}

// Large Mobile: no pdf button //
@include media-breakpoint-down(md) {
  .card {
    .cell {
      &.action {
        &:not(.mobile-visible) {
          display: none;
        }
      }
      &.badge-cell {
        padding-top: 0;

        &:empty {
          padding: 0;
        }
      }
    }
  }
}

// Small Desktop: Hidden status labels //
@include media-breakpoint-only(lg) {
  .card {
    &:not(.--simple) {
      .status {
        padding: 0;
        width: 14px;

        .status-label {
          max-width: 12px;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            background: $color-bg-ix;
            border-radius: ix-prop('br');
            margin: -2px;
            margin-top: -9px;
            max-width: max-content;
            overflow: visible;
            padding: 2px;
            padding-right: $gutter-mini;
            position: absolute;
          }
        }
      }
    }

    .contact-card-label {
      display: none !important;
    }

    .contact-cell-mobile {
      // display: block !important;
      // align-self: center;
    }

    .contact-cell-balance {
      align-self: auto !important;
    }

    .--align-center {
      align-self: auto !important;
    }

    .contact-cell {
      display: none !important;
    }
  }
}

/* ---------------------------------------------------
   Labs & Plugins
----------------------------------------------------- */

.labs-list {
  max-width: $card-max-width;
  margin: 0 auto;

  .lab-card,
  .plugin-card {
    margin-bottom: $gutter-mini;
  }

  .plugin-image-card {
    @extend .d-flex-col;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    justify-content: center;
    align-items: center;
    text-align: center;

    width: 120px;
    height: 120px;
    margin: $gutter-sm $gutter-mini;

    background-color: #ffffff;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 65px;

      img {
        width: 55px;
      }
    }

    &:hover {
      box-shadow: 0px 0px ix-prop('br', 'base') ix-prop('br', 'small')
        ix-color('gray-300');
      transform: scale(1.1);
    }
  }

  .disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    transition: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}

.plugins-list {
  @extend .labs-list;

  max-width: 800px !important;
  justify-content: center !important;
}
