/* ---------------------------------------------------
    HTML Attributes
----------------------------------------------------- */

button {
  @include transition;
  cursor: pointer;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: inherit;
}

/* ---------------------------------------------------
    Global Attributes
----------------------------------------------------- */

.button {
  border-radius: ix-prop('br');
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-14;
  min-height: $button-height;
  margin: 0;
  outline: none;
  padding: $button-pad-top $button-pad-side;
  position: relative;
  text-decoration: none;
  width: auto;

  &.d-block {
    width: 100%;
  }

  &.--medium {
    font-size: $font-size-12;
    min-height: auto;
    padding: 8px 12px;
  }

  &.--small {
    // border-radius: ix-prop('br', 'small');
    font-size: $font-size-12;
    min-height: auto;
    padding: 5px 12px;
  }
}

/* ---------------------------------------------------
    Primary Call to Action
----------------------------------------------------- */

.button-primary {
  @extend .bg-brand-200;
  @extend .color-ix-white;
  @extend .font-bold;
  text-align: center;
  min-width: 100px;
  
  &:hover:not([disabled]) {
    @extend .bg-brand-300;
  }

  .icon {
    color: $color-txt-white;
  }
}

/* ---------------------------------------------------
    Secondary Button
----------------------------------------------------- */

.button-secondary {
  @extend .font-bold;
  @extend .bg-ix-white;
  @extend .color-brand-primary;

  &:hover:not([disabled]) {
    color: $color-brand-hover;
  }
}

/* ---------------------------------------------------
    Button Outline
----------------------------------------------------- */

.button-outline {
  @extend .font-bold;
  @extend .bg-ix-white;
  @extend .color-brand-primary;
  border: 1px solid $color-brand-primary;
  padding: calc(#{$button-pad-top} - 1px) calc(#{$button-pad-side} - 1px);
  text-align: center;
  
  &:hover:not([disabled]) {
    color: $color-brand-hover;
  }

  &.--gray {
    @extend .border-light;
    border-radius: ix-prop('br');
    color: $color-txt-sec-focus;
  }

  &.--danger {
    color: ix-color('red-200');
    border-color: ix-color('red-200');
    &:hover {
      color: ix-color('red-300');
    }
  }

  &.--red {
    border: 1px solid ix-color('red-300');
    color: ix-color('red-300');
  }
}

/* ---------------------------------------------------
    Button Cancel
----------------------------------------------------- */

.button-plain-text {
  @extend .bg-ix-white;
  @extend .font-regular;
  background-color: transparent;
  color: $color-txt-sec-no-focus;
  font-size: $font-size-12;
  text-align: center;
  padding: $button-pad-top 0;

  &:hover:not([disabled]) {
    color: $color-txt-primary;
  }
  
}

/* ---------------------------------------------------
    Button Cancel
----------------------------------------------------- */

.button-dashed {
  @extend .color-brand-primary;
  @extend .uppercase;
  @extend .bold;
  background-color: transparent;
  border-bottom: 1px dashed $color-borders;
  border-radius: 0;
  border-top: 1px dashed $color-borders;
  font-size: $font-size-12;

  &:not(:first-child) {
    border-left: 1px dashed $color-borders;
  }

  &:hover:not([disabled]) {
    color: $color-brand-hover;
  }
}

/* ---------------------------------------------------
    After/Before Elements for Buttons
----------------------------------------------------- */

// Arrow point to right //
.--arrow-right {
  padding-right: $gutter * 2;
  position: relative;
  text-align: left;

  &:after {
    content: '\25b8';
    position: absolute;
    right: $gutter-sm;
  }
}

// Arrow point left //
.--arrow-left {
  position: relative;

  span {
    &:before {
      color: $color-txt-interactive;
      content: '\25c0';
      padding-right: $gutter;
    }
  }
}

// hide and show effect //
.--hide-hover {
  height: $button-height;
  min-width: 55px;
  overflow: hidden;
  position: relative;

  .label {
    position: absolute;
    top: 12px;
    white-space: nowrap;
    display: flex;
  }
  span {
    &:before {
      font-size: $font-size-12;
      padding-right: $gutter-lg;
    }
  }

  @include media-breakpoint-up(md) {
    &:not([disabled]) {
      &:hover {
        @include transition(0.2s);
        border: 1px solid $color-borders-dark;

        .label {
          color: $color-txt-primary;
          position: static;
        }
      }
    }
  }
}

/* ---------------------------------------------------
    Inactive Buttons
----------------------------------------------------- */

button[disabled],
.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    .tooltip-text {
      visibility: visible;
    }

    @include media-breakpoint-down(md) {
      .tooltip-text {
        display: none;
      }
    }
  }
}

/* ---------------------------------------------------
    Button Link
----------------------------------------------------- */
.button-link {
  background-color: transparent;
  min-height: auto;
  padding: 0;
  
  &.color-brand-primary {
    &:hover {
      color: $color-brand-hover
    }
  }
}

/* ---------------------------------------------------
    Anchors
----------------------------------------------------- */

a {
  &.--icon {
    .icon {
      margin-right: $gutter-mini;
    }
  }
}