/* ---------------------------------------------------
   IX Legacy
----------------------------------------------------- */

body {
  font-weight: inherit;
  overflow: auto;
  -webkit-font-smoothing: auto;
  a {
    color: initial;
  }
}

#accountList {
  line-height: normal;
}

#ix_header_v3 .flex-list {
  display: -webkit-box !important;
}

button,
input,
optgroup,
select,
textarea {
  line-height: normal;
}

.dk-screen {
  .container {
    max-width: 1170px;

    @include media-breakpoint-up(xl) {
      width: 1170px;
    }
    @include media-breakpoint-down(lg) {
      width: 970px;
    }
    @include media-breakpoint-down(md) {
      width: 750px;
    }
  }
}

#content {
  background-color: $color-bg-ix;
  // IX FOOTER
  position: relative;
  height: inherit;
  // tool bar position
  transform: none;
}
#root {
  height: 100%;
}

#footer {
  position: absolute;
  bottom: auto;
  // Mobile Footer
  @include media-breakpoint-down(md) {
    display: block !important;

    ul {
      min-width: 100%;
    }

    .pull-right {
      &:first-child {
        float: unset !important;
      }
      &:last-child {
        float: left !important;
      }
    }
  }
}

#no-drag {
  min-height: 100%;
}

// for filters dropdown in advanced search & all other checkboxs
.checkbox {
  float: none;
  margin: inherit;
}

// for dropdown button

.label {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: inherit;
  padding: initial;
  text-align: inherit;
}
.button {
  text-align: inherit;
}

// for summary table
.summary-table {
  .row {
    &:after {
      content: none;
    }
    &:before {
      content: none;
    }
  }
}

// IX HEADER FIX
#accountList {
  line-height: normal;
}
button,
input,
optgroup,
select,
textarea {
  line-height: normal;
}

.dk-screen {
  .container {
    max-width: 1170px;

    @include media-breakpoint-up(xl) {
      width: 1170px;
    }
    @include media-breakpoint-down(lg) {
      width: 970px;
    }
    @include media-breakpoint-down(md) {
      width: 750px;
    }
  }
}

// primary button IX hover
.button-primary {
  &:hover {
    color: ix-color('ix-white');
  }
  &.disabled {
    &:hover {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

// input border not green
input {
  &:focus {
    border-color: ix-color('gray-300') !important;
  }
}

//safari ix conflict in all bootstrap containers
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  content: none;
}

//remove safari ix font-weight usage
.label {
  font-weight: inherit;
}

// anchor hovers
a {
  &:hover {
    color: initial;
    opacity: inherit;
    text-decoration: inherit;
  }
}
.search-filter.favorites li:hover a:hover {
  color: $color-txt-interactive;
}

//remove ix adding color to all tag label
label {
  color: inherit;
}

//give back link anchor color for IX Header
#v3_links {
  a {
    color: #00bb80;
    &:hover {
      color: #00bb80;
      opacity: 0.75;
    }
  }
}

#trialEndingWarning {
  color: #00bb80;
  &:hover {
    color: #00bb80;
    opacity: 0.75;
  }
}

// remove ix's hover
button[disabled],
.button.disabled {
  &:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// calendar
abbr[title],
abbr[data-original-title] {
  cursor: default;
  border-bottom: none;
}

// Mobile Header
#toolbar {
  .navigation {
    line-height: 20px;
    height: 49px;
    @include media-breakpoint-up(lg) {
      height: 47px;
    }
    .container {
      padding-left: 0;
      padding-right: 0;

      .row {
        display: inherit;
        flex-wrap: unset;

        .navbar-left {
          width: 16.66666667%;
          padding: 15px 30px;
        }

        .navbar-right {
          padding: 15px 30px 15px 15px;
        }
      }
    }
  }
}

.navigation ul.navLinks li a.selected {
  background: $color-bg-ix !important;
}

nav.col-md-12.col-sm-12.col-xs-12.navigation.sb-nav-ix.no-padding {
  padding: 0;
}

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-content:after,
.modal-content:before,
.modal-footer:after,
.modal-footer:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  content: inherit;
}

a.col-md-8.col-sm-8.col-xs-8.logo.pull-left {
  max-height: 28px;
  width: 66.66666667%;
}

header #changeAccount {
  @include media-breakpoint-down(md) {
    line-height: 25px;
  }
}

// give back link anchor color for IX Header //
#v3_links {
  a {
    color: #00bb80;
    &:hover {
      color: #00bb80;
      opacity: 0.75;
    }
  }
}

// IX MODAL //
.modal-dialog {
  .modal-content {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    padding: 0;
    border-radius: 6px;
    transform: none;

    p {
      color: #666;
    }
  }
}

.modal-content {
  box-shadow: none;
}

.alert-success {
  border: none;
}

.modal-content{
  background: ix-color('ix-white')
}

// Mobile Menus is Desktop

@include media-breakpoint-up(lg) {
  .snap-drawers {
    display: none;
  }
}

// IX NAVIGATION
.navigation .container .row,
.navigation .modal-content .row {
  display: flex;
}

#toolbar {
  -webkit-font-smoothing: antialiased;
}

// remove create new documents menu 
.navbar-right {
  pointer-events: none;
}

/* ---------------------------------------------------
   New Listing
----------------------------------------------------- */

// Global //
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: inherit;
  line-height: initial;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: initial;
}

p,
dd,
li {
  color: inherit;
}

label {
  color: inherit;
  font-size: inherit;
  text-align: inherit;
  margin-bottom: inherit;
  font-family: inherit;
}

.checkbox {
  float: none;
  margin: inherit;
}

.label {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: inherit;
  padding: initial;
  text-align: inherit;
}

.button {
  text-align: inherit;
}

.summary-table {
  .row {
    &:after {
      content: none;
    }
    &:before {
      content: none;
    }
  }
}

.button-primary {
  &:hover {
    color: ix-color('white');
  }
  &.disabled {
    &:hover {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

// input border green //
input {
  &:focus {
    border-color: ix-color('gray-300') !important;
  }
}

// anchor hovers //
a {
  &:hover {
    color: initial;
    opacity: inherit;
    text-decoration: inherit;
  }
}

.search-filter.favorites li:hover a:hover {
  color: $color-txt-interactive;
}


// remove ix's hover //
button[disabled],
.button.disabled {
  &:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// calendar //
abbr[title],
abbr[data-original-title] {
  cursor: default;
  border-bottom: none;
}

.button,
.primaryButton,
.primaryButton:visited,
.secondaryButton,
.secondaryButton:visited {
  line-height: inherit;
}

.select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  line-height: inherit !important;
}

.loading {
  background: none;
  float: unset;
  height: 100%;
  opacity: inherit;
  width: 100%;
}

// Modal //
.modal-container {
  .modal-content {
    border: none;
  }
}

.finalization-screen {
  .text-paragraph {
    margin: 14px 0;
  }
}

.status-bar{
  margin-right: -#{$gutter * 2} !important;
  margin-left: -#{$gutter * 2} !important;
}

// Button dropdown //

.button-dropdown {
  transition: none;
  
  @include media-breakpoint-up(lg) {
    z-index: 1; // for v2 navigation 
    
    &.help-info-panel { 
      z-index: 0;
      
      .content-to-hide {
        box-sizing: border-box;
        z-index: 1;
      }
    }
  }
}

#globalReset {   
  @include media-breakpoint-up(lg) {
    z-index: 1; // for v2 navigation 
  }
}

// safari conflicts //
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  content: none;
}

@include media-breakpoint-up(lg) {
  .search-filter {
    z-index: 2; // for v2 navigation 
  }

  .favorites {
    z-index: 3; // for v2 navigation 
  }
}