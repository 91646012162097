/* -----------------------------------
    Tables 
-------------------------------------*/

.loading-tables {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .ix-loading {
    display: block;
    height: auto;
    left: 50%;
    margin-right: -50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    z-index: 2;
  }

  &:before {
    content: '';
    background-color: $color-bg-ix;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

/* -----------------------------------
    Documents List Table Loading
-------------------------------------*/

.loading-table {
  height: auto;
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.loading-table-mobile {
  height: auto;
  width: 100%;
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

/* -----------------------------------
    Modals
-------------------------------------*/

.loading-modals {
  .ix-loading {
    display: block;
    left: 50%;
    margin: 0;
    margin-right: -50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    z-index: 2;
  }

  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.loading-info {
  align-items: center;
  display: flex;
  flex-direction: row;

  .ix-loading {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
  }

  .span {
    display: inline-block;
  }
}
