/* ---------------------------------------------------
  Basic footer
----------------------------------------------------- */

.footer-simple {
  background-color: ix-color('gray-600');
  height: 66px;
  width: 100%;

  .row {
    width: 100%;
    padding: $gutter-mini calc($gutter * 2);
  }

  .logo {
    .img {
      height: 20px;
      width: auto;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .text {
    font-size: $font-size-14;
    padding: $gutter-mini;
    text-align: right;

    @include media-breakpoint-down(md) {
      font-size: $font-size-12;
      text-align: center;
    }
  }

  a {
    &:hover {
      opacity: 0.6;
    }
  }
}
