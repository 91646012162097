/* -----------------------------------
  Inside Menu
-------------------------------------*/

.inside-menu {
  .button-dropdown {
    @extend .box-shadow-dropdowns;

    // width: 90% !important;
    margin-top: $gutter;
  }

  .button-dropdown.expanded {
    .content-to-hide {
      @extend .content-to-hide;
      position: initial !important;
      box-shadow: none !important;
      padding: 10px 0px !important;
    }
  }

  .button-dropdown:first-child {
    margin-top: 0 !important;
  }
}
