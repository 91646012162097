/* -----------------------------------
  Summary Table
-------------------------------------*/

.summary-table {
  position: relative;

  .summary-container {
    @extend .border-light;
    border-radius: ix-prop('br');
    color: $color-txt-sec-focus;
    padding-left: $gutter-sm;
    padding-right: $gutter-sm;

    &.--first {
      border-radius: ix-prop('br') ix-prop('br') 0 0;
    }

    &.--middle {
      border-radius: 0;
      border-top: 0;
    }

    &.--last {
      border-top: 0;
      border-radius: 0 0 ix-prop('br') ix-prop('br');
      margin-bottom: $gutter;
    }

    .summary-row {
      padding-top: $gutter;

      &:last-child {
        padding-bottom: $gutter;
      }
    }
  }
}
