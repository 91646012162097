/* -----------------------------------
    Banners
-------------------------------------*/

.banner-container {
  font-size: $font-size-14;

  /* -----------------------------------
			Top Banner
	-------------------------------------*/

  &.top-bar {
    @extend .bg-ix-white;
    text-align: center;

    .banner-text {
      padding: $gutter calc($gutter/2);
    }
  }

  /* -----------------------------------
		Feature Not Avaliable Banner
	-------------------------------------*/

  &.--not-avaliable {
    @extend .bold;
    @extend .border-light;
    align-items: center;
    background-color: $color-bg-ix;
    border-radius: ix-prop('br');
    color: $color-txt-sec-no-focus;
    display: flex;
    padding: $gutter-sm $gutter-lg;
    justify-content: space-between;
  }
}
