/* ---------------------------------------------------
   Properties
----------------------------------------------------- */

$properties: (
  br: (
    // border-radius
    small: 2px,
    base: 4px,
    large: 10px,
  ),
  pad: (
    // padding
    base: 10px,
  ),
);

@function ix-prop($property, $attribute: 'base') {
  @return map-get(map-get($properties, $property), $attribute);
}

/* ---------------------------------------------------
   Typography 
----------------------------------------------------- */

.bold {
  @extend .font-bold;
}

.color-gray-base {
  color: $color-txt-sec;
}

.color-gray-light {
  color: $color-txt-sec-no-focus;
}

.color-brand-primary {
  color: $color-brand-primary;
}

/* ---------------------------------------------------
   Display
----------------------------------------------------- */

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-table {
  display: table;
  width: 100%;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-none {
  display: none;
}

.d-contents {
  display: contents;
}

.d-flex-col {
  @extend .d-flex;
  flex-direction: column;
}

.d-flex-row {
  @extend .d-flex;
  flex-direction: row;
}

/*
************************************************   
Margins
*/

$margins: (
  top: $gutter,
  bottom: $gutter,
  left: $gutter,
  right: $gutter,
);

@each $name, $prop in $margins {
  .margin-#{$name} {
    margin-#{$name}: $prop;
  }
}

$no-margin: (
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
);

@each $name, $prop in $no-margin {
  .no-margin-#{$name} {
    margin-#{$name}: $prop;
  }
}

$mini-margin: (
  top: $gutter-mini,
  bottom: $gutter-mini,
  left: $gutter-mini,
  right: $gutter-mini,
);

@each $name, $prop in $mini-margin {
  .margin-#{$name}-mini {
    margin-#{$name}: $prop;
  }
}

/*
************************************************   
Paddings
*/

$paddings: (
  top: $gutter,
  bottom: $gutter,
  left: $gutter,
  right: $gutter,
);

@each $name, $prop in $paddings {
  .padding-#{$name} {
    padding-#{$name}: $prop;
  }
}

$no-padding: (
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
);

@each $name, $prop in $no-padding {
  .no-padding-#{$name} {
    padding-#{$name}: $prop;
  }
}

/*
************************************************   
Text Aligns
*/

$text-aligns: (
  left: left,
  center: center,
  right: right,
);

@each $name, $prop in $text-aligns {
  .text-align-#{$name} {
    text-align: $prop;
  }
}

/* ---------------------------------------------------
   Transitions
----------------------------------------------------- */

@mixin transition($s: 0.1s) {
  transition: all $s ease-in-out;
}

/* ---------------------------------------------------
   Hovers
----------------------------------------------------- */

@mixin list-hover {
  &:hover {
    @extend .color-brand-primary;
    @include transition;
    padding-left: $gutter-mini;

    &.selected {
      padding-left: 0;
    }

    &.icon {
      padding-left: 0;
    }
  }
}

a {
  cursor: pointer;
  &.color-brand-primary {
    &:hover {
      color: $color-brand-hover;
    }
  }
}

/* ---------------------------------------------------
  Classes
----------------------------------------------------- */

// any selected element
.selected {
  color: $color-txt-selected;
}

// Box shadows //
.box-shadow-focus {
  box-shadow: 0 0 8px ix-color('gray-300');
}

.box-shadow-dropdowns {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.box-shadow-dropdown-top {
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
}

// Borders //

.border-light {
  border: 1px solid $color-borders !important;
}

.border-dark {
  border: 2px solid $color-borders-dark !important;
}

// Containers //
.container-bg-white {
  @extend .bg-ix-white;
  border-radius: ix-prop('br');
}

// Containers //
.container-bg-white-border {
  @extend .bg-ix-white;
  @extend .border-light;
  border-radius: ix-prop('br');
}

// separation bar //
.sep {
  border-top: 1px solid $color-borders;
}

.sep-light {
  border-top: 1px solid ix-color('gray-200');
}

// can't be selected //
.prevent-selection {
  user-select: none;
}

.prevent-interaction {
  pointer-events: none;
}

// open dropdowns from right to left //
.open-to-left {
  border-radius: ix-prop('br') 0 ix-prop('br') ix-prop('br');
  right: 0;
}

// Turn off number input spinners //
.spinner-off {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}

// turn invisible without losing space //
.no-opacity {
  opacity: 0;
}

// Hide scroll //

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// Inline List //

.inline-list {
  li {
    @extend .color-gray-light;
    display: inline-block;
    padding: 0 $gutter-mini;

    &:not(:last-child) {
      border-right: 2px solid ix-color('gray-300');
    }
  }
}

/* ---------------------------------------------------
   Mobile
----------------------------------------------------- */

.mobile-only {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.desktop-only {
  display: block;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

/* ---------------------------------------------------
  Dimentions
----------------------------------------------------- */

.width-100 {
  width: 100%;
}
