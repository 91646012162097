/* ---------------------------------------------------
   Colors
----------------------------------------------------- */

$color-bg-ix: ix-color('gray-100');
$color-bg-selected: ix-color('brand-100');
$color-brand-primary: ix-color('brand-200');
$color-brand-hover: ix-color('brand-300');
$color-gray-background: ix-color('gray-200');
$color-borders: ix-color('gray-300');
$color-borders-hover: ix-color('gray-500');
$color-borders-dark: ix-color('gray-700');
$color-bg-toolbar: ix-color('gray-700');

/* ---------------------------------------------------
   Typography
----------------------------------------------------- */

// Line heights //
$line-height-regular: 1.5em;

// Color //
$color-txt-primary: ix-color('gray-700'); 
$color-txt-white: ix-color('ix-white');
$color-txt-sec-focus: ix-color('gray-600');
$color-txt-sec: ix-color('gray-500');
$color-txt-sec-no-focus: ix-color('gray-400');
$color-txt-interactive: $color-brand-primary;
$color-txt-selected: $color-brand-primary;

/* ---------------------------------------------------
   Buttons
----------------------------------------------------- */

$button-filter-height: 50px;
$button-filter-pad-top: 10px;
$input-height-sm: 30px;
$button-height: 44px;
$button-pad-side: 20px;
$button-pad-top: 14px;

/* ---------------------------------------------------
   Gutters
----------------------------------------------------- */

$gutter-mini: 5px;
$gutter-sm: 15px;
$gutter: 20px;
$gutter-lg: 25px;

/* ---------------------------------------------------
   Z-INDEX - bootstrap reference
----------------------------------------------------- */

$zindex-button: 900 !default;
// $zindex-dropdown:          1000 !default;
$zindex-dropdown-header: 1010 !default;
// $zindex-sticky:            1020 !default;
$zindex-toolbar: 1025 !default;
// $zindex-fixed:             1030 !default;
$zindex-fixed-content: 1035 !default;
// $zindex-modal-backdrop:    1040 !default;
// $zindex-modal:             1050 !default;
$zindex-modal-over: 1055 !default;
// $zindex-popover:           1060 !default;
// $zindex-tooltip:           1070 !default;

/* ---------------------------------------------------
   Others
----------------------------------------------------- */

// Modals
$modal-height-lg: 585px;
$modal-width-sm: 500px;
$modal-width-md: 800px;
$modal-preview-height: 360px;
$modal-editor-height: 290px;
$modal-editor-input-height: 240px;

// Min height for loading
$summary-table-height: 450px;
$list-table-height: 430px;
$list-table-mobile-height: 660px;

// Checkbox
$checkbox-size: 16px;
$checkbox-size-lg: 22px;

// Document Sequence number
$sequence-width: 120px;
$sequence-width-sm: 90px;

// Mobile
$mobile-header-height: 49px;

// Dropdowns
$dropdown-width: 300px;
$dropdown-width-md: 350px;
$dropdown-width-lg: 390px;

// Tool bar
$toolbar-height: 95px;

// Step Circle
$step-circle: 24px;

// Tooltips
$tooltip-width: 150px;

// Labs listing
$card-max-width: 675px;
$lab-img-width: 65px;

// Page header
$pageheader-img-width: 70px;
$pageheader-img-height: 70px;
