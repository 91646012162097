// Document Status //

.status-label {
  font-size: $font-size-9;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  width: max-content;

  .icon {
    padding-right: 2px;
    font-size: $font-size-14;
    vertical-align: -0.25em;
  }
}

.registration-label {
  font-size: $font-size-12;

  .icon {
    margin-right: $gutter-mini;
    font-size: $font-size-14;
  }

  .fa-circle-check,
  .fa-circle-exclamation,
  .fa-circle-xmark,
  .fa-ban {
    display: none;
  }

  &.status-color-inactive {
    @extend .font-regular;
    .fa-circle-xmark {
      display: inline-block;
    }
  }

  &.status-color-partial {
    .fa-circle-exclamation {
      display: inline-block;
    }
  }

  &.status-color-total {
    .fa-circle-check {
      display: inline-block;
    }
  }

  &.status-color-bold {
    .fa-ban {
      display: inline-block;
    }
  }
}
