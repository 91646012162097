/* ---------------------------------------------------
   Summary Rows
----------------------------------------------------- */

.summary-row {
  width: 100%;

  .summary-left {
    @extend .bold;
    float: left;
  }

  .summary-right {
    @extend .bold;
    @extend .text-align-right;
    white-space: nowrap;
  }

  &.--status {
    font-size: $font-size-12;
  }

  &.--small {
    font-size: $font-size-12;

    .summary-left,
    .summary-right {
      @extend .font-regular;
    }
  }

  &.--medium {
    font-size: $font-size-14;

    .summary-left {
      font-size: $font-size-14;
    }
  }

  &.--large {
    font-size: $font-size-20;

    .summary-left {
      font-size: $font-size-20;
    }
  }
}

/* ------------------------------------------
    Title Button Rows
--------------------------------------------- */

.title-button {
  font-size: $font-size-16;
  margin-bottom: $gutter-sm;

  &.--subtitle {
    font-size: $font-size-14;
    margin-bottom: calc($gutter/2);
  }
}

/* ------------------------------------------
    Title Icon Rows
--------------------------------------------- */

.title-icon {
  @include list-hover;

  .icon {
    color: $color-txt-sec-no-focus;

    &:not(:last-child) {
      margin: 0 5px;
    }

    &:hover {
      padding-left: 0;
      color: $color-txt-sec;
    }
  }

  a {
    &:not(:last-child) {
      margin: 0 5px;
    }
  }
}

.selected {
  .title-icon {
    // Remove Hover //
    &:hover {
      padding-left: 0;
    }
  }
}
