.lab-card {
  background-color: ix-color('ix-white');
  border-radius: ix-prop('br');
  padding: $gutter-sm $gutter-mini;

  .text-paragraph {
    margin: $gutter-mini 0 0 0;
    color: $color-txt-sec-focus;
    font-size: $font-size-12;
    line-height: $font-size-17;
  }

  .action-block, .info-block {
      align-self: center;
  }
  
  @include media-breakpoint-down(sm) { 
    .action-block {
      width: 100%;
      margin-top: $gutter;
    }
  }

  .status-block {
    align-self: center;
  }

  .img-block {
    .img {
      max-height: 32px;
      min-height: 32px;
      position: absolute;
      top: -8px;
      left: 10px;
    }
  }
}

.plugin-card {
  @extend .lab-card;
  background-color: #FFFFFF;

  padding: 0;
  margin-top: $gutter;

  .row { 
    padding: $gutter-sm;
  }

  .col-auto {
    width: calc($gutter-sm * 5);
    margin: $gutter-mini 0;
    
    .img {
      width: calc(($gutter-mini * 5) * 2);
    }
  }

  .action-block {
    button {
      width: 90%;
    }
  }

  &--name {
    @extend .text-paragraph;
    @extend .--small;

    font-weight: bold;
  }

  &--description {
    margin-top: $gutter-sm;
    font-size: $font-size-14;
  }

  .plugin-card--footer {
    @extend .d-flex;
    height: calc($gutter-sm * 3);
    padding: 0px $gutter-lg;

    background-color: ix-color('ix-white');
  }
}