/* -----------------------------------
  Advanced Search
-------------------------------------*/

.advanced-search.--mol {
  padding: 0;
  .search-filter {
    .header {
      width: 100%;
    }
  }
  // Filter row //
  .search-filters-row {
    .col-filter {
      padding: 0 $gutter-mini;

      &:first-child {
        padding-left: $gutter-sm;
      }

      &:nth-last-child(2) {
        padding-right: $gutter-sm;

        .content-to-hide {
          @extend .open-to-left;
        }
      }
    }

    .mobile-filter {
      @include media-breakpoint-up(lg) {
        display: none;
        padding: $gutter-sm 0;
      }
    }
  }
  // Search and favorites row //
  .search-bar-row {
    .col {
      padding: 0 5px;

      &:first-child {
        padding-left: $gutter-sm;
      }

      &:last-child {
        @extend .col-2;
        padding-left: $gutter-mini;
        padding-right: $gutter-sm;

        .content-to-hide {
          @extend .open-to-left;
        }

        @include media-breakpoint-down(lg) {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
    // Search and favorites row: Mobile //
    @include media-breakpoint-down(md) {
      .col {
        &:last-child {
          max-height: $button-height;
          max-width: calc(#{$button-height} + #{$gutter});
          min-width: calc(#{$button-height} + #{$gutter});

          .search-filter.favorites .header {
            padding: 13px 14px;
          }
        }
      }
    }
  }
}
