.tool-bar {
  align-items: center;
  background-color: $color-bg-toolbar;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  height: $toolbar-height;
  min-height: $toolbar-height;
  min-width: 100%;
  padding: 0;
  position: fixed;
  z-index: $zindex-toolbar;

  .row {
    align-items: center;
    min-width: 100%;
  }

  /******* Docs selected info *******/
  .doc-info {
    color: $color-txt-white;
    text-align: right;

    .doc-info-container {
      display: inline-block;
      width: auto;
      height: 100%;

      // Horizontal line //
      &:after {
        content: '';
        position: absolute;
        border-top: 1px solid ix-color('gray-600');
        top: 50%;
        right: 0;
        left: 0;
      }
    }

    .checkbox {
      font-size: $font-size-12;
      margin-top: $gutter;
      text-align: left;

      span {
        @extend .bold;

        &.label-rm {
          @extend .font-regular;
        }
      }
    }

    .doc-selected {
      text-align: left;
      // margin-bottom: $gutter;
      vertical-align: middle;
      color: $color-txt-white;
      flex: 1;

      span {
        @extend .bold;
      }

      .doc-selected-inner {
        padding-bottom: calc($gutter/2);
        // Clean selection //
        .selection-cleaner {
          cursor: pointer;
          color: $color-txt-sec-no-focus;
          margin-right: $gutter-mini;
          font-size: $font-size-14;
        }
      }
    }

    .doc-select-all {
      flex: 1;

      .doc-select-all-inner {
        padding-top: calc($gutter/2);
      }
    }

    // Vertical line //
    &:after {
      content: '';
      border-right: 1px solid ix-color('gray-600');
      position: absolute;
      right: 0;
      min-height: $toolbar-height;
    }
  }

  /******* Docs Actions *******/
  .doc-sec-actions {
    align-items: center;
    display: flex;

    .button-simple-icon {
      @extend .--secondary;
    }

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: calc($gutter/2);
      }
    }
  }

  .doc-actions {
    align-items: center;
    border-spacing: 0;
    display: flex;
    text-align: center;
    padding-right: 0;

    .button-primary {
      &:not(:last-child) {
        margin-right: calc($gutter/2);
      }
    }

    .tooltip-hover[disabled] {
      // fake disabled //
      &:hover {
        background-color: rgba(24, 187, 128, 0.5);
        opacity: 1;

        .label {
          color: #999;
        }
      }
    }

    &:empty {
      display: none;
    }
  }

  /****************************** MOBILE **********************************/
  .doc-sec-actions {
    .header {
      @extend .button;
      background-color: ix-color('gray-600');
      color: $color-txt-white;
      display: none;
      padding: 14px $gutter-sm * 2 $button-pad-top $button-pad-side;

      &:after {
        content: '\25be';
        position: absolute;
        right: $gutter;
      }
    }
  }

  @include media-breakpoint-down(md) {
    overflow: visible;
    display: block;
    padding: 0 calc($gutter/2);

    // Information //
    .doc-selected {
      padding: $gutter-sm $gutter 0;
      text-align: left;
    }

    .doc-info {
      border-bottom: 1px solid ix-color('gray-600');
      text-align: center;

      .doc-info-container {
        min-width: 100%;

        .doc-selected {
          border-right: 1px solid ix-color('gray-600');
          display: inline-block;
          margin: 0;
          max-width: 55%;
          padding: $gutter-sm calc($gutter/2);
          text-align: right;
          vertical-align: middle;

          &:after {
            content: none;
          }
        }

        .checkbox {
          display: inline-block;
          font-size: $font-size-14;
          height: auto;
          margin: $gutter-sm 0 $gutter-sm $gutter-mini;
          max-width: 40%;
          vertical-align: middle;

          span {
            &.label-rm {
              display: none;
            }
          }
        }
      }
      &:after {
        content: none;
      }
    }

    // Main Action //
    .doc-actions {
      margin: $gutter-sm 0;

      .button-primary {
        display: inline-block;
        width: 100%;
      }
    }

    // Other Options //
    .doc-sec-actions {
      margin: 0;
      padding: 0 $gutter-sm;

      // Other actions Dropdown //
      .options-dropdown {
        margin: $gutter-sm 0;
        min-width: 100%;
        width: auto;

        .header {
          display: inline-block;
          height: 100%;
          min-width: 100%;
        }

        .content-to-hide {
          background-color: ix-color('gray-600');
          border-radius: ix-prop('br') ix-prop('br') 0 0;
          bottom: $button-height;
          display: none;
          margin-bottom: calc($gutter/2);
          min-width: calc(100% - #{$gutter-sm * 2});
          max-width: calc(100% - #{$gutter-sm * 2});
          position: absolute;
          z-index: $zindex-dropdown;

          li {
            border-bottom: 1px solid $color-borders;
            display: block;
            margin: 0;
            padding: calc($gutter/2) $gutter;

            &:last-child {
              border-bottom: 1px solid $color-borders;
            }

            .button-simple-icon {
              width: 100%;
            }
          }
        }

        &.expanded {
          .header {
            border-radius: 0 0 ix-prop('br') ix-prop('br');
          }

          .content-to-hide {
            display: block;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .doc-actions {
      margin-bottom: 0;
      max-width: 100%;
      flex: 0 0 100%;
      padding: $gutter-sm;

      .button {
        text-align: left;
      }
    }

    .doc-sec-actions {
      padding: 0 $gutter-sm;

      .options-dropdown {
        .content-to-hide {
          min-width: calc(100% - #{$gutter-sm * 2});
          max-width: calc(100% - #{$gutter-sm * 2});
        }
      }
    }

    .doc-selected {
      span {
        margin-right: 0;
        padding: 0;
      }
    }
  }
}
