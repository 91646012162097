.pagination {
  font-size: 0;
  flex-flow: wrap;

  button {
    @extend .button;
    @extend .button-secondary;
    @extend .font-regular;
    border-radius: 0;
    color: $color-txt-sec;
    margin-right: 1px;
    margin-bottom: 1px;
    
    &:hover {
      color: $color-txt-primary;
      box-shadow: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &.previous {
      border-radius: ix-prop('br', 'small') 0 0 ix-prop('br', 'small');
    }

    &.next {
      border-radius: 0 ix-prop('br', 'small') ix-prop('br', 'small') 0;
    }

    &.current-page {
      @extend .font-bold;
      color: $color-txt-interactive;
    }
  }

  .icon {
    display: none;
  }

  // Mobile //
  @include media-breakpoint-down(md) {
    .icon {
      display: block;
    }

    .previous,
    .next {
      span {
        display: none;
      }
    }
  }

  @media (max-width: 375px) {
    .button {
      &:not(.previous):not(.next):not(.current-page) {
        display: none;
      }
    }
  }
}
