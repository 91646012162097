/* ---------------------------------------------------
   Color Palette
----------------------------------------------------- */

$colors: (
  ix-white: #fbfbfc,
  gray-100: #f4f5f5,
  gray-200: #dfe0e1,
  gray-300: #c0c1c4,
  gray-400: #8b8e92,
  gray-500: #595b5f,
  gray-600: #3b3d3f,
  gray-700: #141415, 
  brand-100:#DCEFE8,
  brand-200: #18BB80,
  brand-300: #139062,
  green-100: #18BB80,
  red-100: #F8C9CA,
  red-200: #C11A1A,
  red-300: #A2151A,
  orange-100: #FF9933,
  purple-100: #B67DCB,
  blue-100: #DDE6FA,
  blue-200: #1549A2,
  yellow-100: #F9E6C8,
  yellow-200: #81550E,
);

/* ---------------------------------------------------
  Classes
----------------------------------------------------- */

@each $name, $prop in $colors {
  .color-#{$name} {
    color: $prop;
  }
  .bg-#{$name} {
    background-color: $prop;
  }
}

@function ix-color($property) {
  @return map-get($colors, $property);
}

/*
************************************************   
Status Color
*/

$status-color: (
  paid: ix-color('brand-200'),
  canceled: ix-color('red-200'),
  draft: ix-color('orange-100'),
  final: ix-color('blue-200'),
  duplicate: ix-color('purple-100'),
  refused: ix-color('gray-400'),
  accepted: ix-color('green-100'),
  active: ix-color('green-100'),
  inactive: ix-color('gray-400'),
  partial: ix-color('yellow-200'),
  total: ix-color('green-100'),
  finalized: ix-color('gray-600'),
);

@each $name, $prop in $status-color {
  .status-color-#{$name} {
    color: $prop;
  }

  .status-bg-#{$name} {
    background-color: $prop;
  }
}

/*
************************************************   
Alert Color
*/

$alert-color: (
  "information" ix-color('blue-200') ix-color('blue-100'),
  "warning" ix-color('yellow-200') ix-color('yellow-100'),
  "error" ix-color('red-300') ix-color('red-100'),
  "success" ix-color('brand-300') ix-color('brand-100'),
  "notification" ix-color('gray-600') ix-color('gray-200'),
  "active"  ix-color('gray-600') transparent
);

@each $name, $color, $bg in $alert-color {
  .color-#{$name} {
    color: $color;
  }
  
  .alert-#{$name} {
    color: $color;
    background-color: $bg;
  }
}
