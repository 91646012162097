/* -----------------------------------
  Table options Row
-------------------------------------*/

.table-options {
  min-height: $input-height-sm;
  margin-bottom: calc($gutter/2);

  .select-col {
    @extend .col-auto;
    min-height: 20px;
    position: relative;

    .checkbox {
      color: $color-txt-sec-focus;
      display: inline-block;
      font-size: $font-size-12;
    }
    // Hard reset button //
    .button {
      @extend .--small;
      border-radius: ix-prop('br');
      display: inline-block;
      min-width: max-content;
      position: absolute;
      text-transform: uppercase;
      z-index: $zindex-button;

      @include media-breakpoint-up(lg) {
        margin-left: calc($gutter/2);
        top: -3px;

        &.no-margin-left {
          margin-left: 0;
        }
      }
    }
  }

  .options-col {
    @extend .col;

    .option {
      display: inline-block;
      position: relative;
    }

    .label-toggle {
      color: $color-txt-sec-focus;
      margin-left: calc($gutter/2);
      margin-right: 0;

      label {
        color: $color-txt-sec-focus;
        font-size: $font-size-12;
      }
    }

    .mobile-filter {
      padding: $gutter-sm 0;

      // mobile //
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}

/* -----------------------------------
  Table options Dropdowns
-------------------------------------*/

.table-options-dropdown {
  position: relative;

  .header {
    @extend .prevent-selection;
    display: inline-block;
    padding: calc($button-filter-pad-top/2) calc($button-pad-side * 1.5)
      calc($button-filter-pad-top/1.5) calc($button-pad-side/1.5);
    position: relative;

    .label {
      color: $color-txt-sec-focus;
      cursor: pointer;
      font-size: $font-size-12;
    }

    &:after {
      @include transition(0.2s);
      color: $color-txt-sec-focus;
      content: '\25be';
      padding-right: calc($gutter/2);
      position: absolute;
      top: 4px;
      right: 0;
    }
  }

  .content-to-hide {
    display: none;
    box-sizing: border-box;
  }

  &.expanded {
    .header {
      @extend .border-light;
      background-color: ix-color('ix-white');
      border-bottom-color: ix-color('ix-white');
      border-bottom: 0;
      border-radius: ix-prop('br') ix-prop('br') 0 0;
      margin: -1px -1px 0 -1px;
      z-index: $zindex-dropdown-header;
    }

    .content-to-hide {
      @extend .bg-ix-white;
      @extend .border-light;
      @extend .text-align-left;
      border-radius: 0 0 ix-prop('br') ix-prop('br');
      display: block;
      margin: 0 -1px -1px -1px;
      min-width: calc(100% + 2px);
      padding: $button-pad-side;
      position: absolute;
      z-index: $zindex-dropdown;

      ul {
        min-width: 90px;

        li {
          cursor: pointer;
          display: block;
          padding: 10px 0;
          min-width: max-content;

          a {
            display: inline-block;
            color: $color-txt-primary;
            font-size: $font-size-12;

            &:hover {
              @include list-hover;
            }

            &.selected {
              color: $color-txt-interactive;
            }
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }

        .icon {
          color: $color-txt-interactive;
          margin-right: 5px;
        }
      }
    }
    .header {
      &:after {
        @include transition(0.2s);
        transform: rotate(180deg);
        padding-left: calc($gutter/2);
        top: 6px;
      }
    }
    .sep {
      margin: $gutter 0;
    }
  }

  // Buttons //
  .button-sort {
    min-height: auto;
    padding: 0 30px;

    .icon {
      color: $color-txt-sec;
      font-size: $font-size-14;
    }

    &:hover {
      .icon {
        color: $color-txt-interactive;
      }
    }

    &.selected {
      .icon {
        color: $color-txt-interactive;
      }
    }
  }
}
