/* ---------------------------------------------------
    Tooltip's Parent Hovers
----------------------------------------------------- */

.tooltip-hover {
  transition: none;

  &.button-simple-icon[disabled],
  &.button[disabled] {
    &:hover {
      opacity: 1;

      .label {
        opacity: 0.5;
      }

      .icon {
        opacity: 0.5;
      }
    }
  }

  &.button-primary[disabled] {
    &:hover {
      background-color: rgba(24, 187, 128, 0.5);
      opacity: 1;
    }
  }
}

/* ---------------------------------------------------
    Tooltip Itselft 
----------------------------------------------------- */

.tooltip-text {
  @extend .box-shadow-dropdowns;
  @extend .font-regular;
  @extend .container-bg-white;
  bottom: 150%;
  font-size: $font-size-10;
  height: auto;
  left: 50%;
  margin-left: -#{calc($tooltip-width/2)};
  opacity: 1;
  padding: 10px;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: $tooltip-width;
  z-index: $zindex-dropdown;
  line-height: normal;

  &:after {
    border-color: ix-color('ix-white') transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 100%;
  }

  // Tooltip type = Error //
  &.--error {
    @extend .alert-error;

    &:after {
      border-color: ix-color('red-100') transparent transparent transparent;
    }
  }

  // Tooltip type = Information //
  &.--info {
    @extend .alert-information;

    &:after {
      border-color: ix-color('blue-100') transparent transparent transparent;
    }
  }

  // Tooltip type = notification //
  &.--notification {
    @extend .alert-notification;
    background-color: ix-color('gray-100');

    &:after {
      border-color: ix-color('gray-100') transparent transparent transparent;
    }
  }

  // Tooltip type = Warning //
  &.--warning {
    @extend .alert-warning;

    &:after {
      border-color: ix-color('yellow-100') transparent transparent transparent;
    }
  }

  &:empty {
    display: none;
  }
}
