/* ---------------------------------------------------
    Button with search icon inside textfield
----------------------------------------------------- */

.input-icon {
  position: relative;
  //padding for symbol defult
  input[type='number'] {
    padding-left: 20px;
  }
  //padding for symbol with 2 chars
  &.--pad-md {
    input[type='number'] {
      padding-left: 35px;
    }
  }
  //padding for symbol with 3 or more chars
  &.--pad-lg {
    input[type='number'] {
      padding-left: 45px;
    }
  }

  .icon {
    color: $color-txt-sec;
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translateY(-50%);
  }

  .text-symbol {
    @extend .bold;
    color: $color-txt-sec;
    cursor: default;
    font-size: $font-size-14;
    left: 8px;
    position: absolute;
    top: 6px;
  }

  &.icon-box {
    input {
      padding-right: $gutter * 2;
    }

    .text-symbol {
      background-color: $color-gray-background;
      border: 1px solid $color-borders;
      right: 0;
      top: 0;
      left: auto;
      padding: 10px 15px;
      border-radius: 0 ix-prop('br') ix-prop('br') 0;
    }
  }
}

/* ---------------------------------------------------
    Textfield with button (search bar)
----------------------------------------------------- */

.searchfield-button {
  position: relative;

  .button {
    height: $button-height - 10;
    min-height: $button-height - 10;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: 5px;
    top: 5px;

    &.disabled {
      &:hover {
        @extend .bg-brand-200;
      }
    }
  }

  // Mobile //
  @include media-breakpoint-down(md) {
    .button {
      background: none;
      padding: 0;

      .icon {
        color: $color-txt-interactive;
        font-size: $font-size-14;
      }

      &.disabled {
        opacity: 1;
      }
    }

    span {
      // label
      display: none;
    }

    input {
      padding: calc($gutter/2) calc($gutter-sm * 2) calc($gutter/2) $gutter;
    }
    .button {
      &:hover {
        background: transparent;
      }

      &.disabled {
        &:hover {
          background: transparent;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    #table_buttonSearch {
      min-width: auto;
    }
  }
}

/* ---------------------------------------------------
    Search filters
----------------------------------------------------- */

.search-filter-date {
  input[type='date'],
  .select {
    width: 100%;
  }

  .row + .row {
    margin-top: 10px;
  }

  .icon {
    color: $color-txt-sec-no-focus;
    font-size: $font-size-20;
  }
}

/* ---------------------------------------------------
    Label + Select Naked
    Label + Toglle
----------------------------------------------------- */

.label-select-naked,
.label-toggle {
  margin-right: $gutter-mini;
  text-align: right;
  display: flex;
  align-items: center;

  label {
    margin-right: 2px;
  }

  &.--tiny {
    label {
      font-size: $font-size-10;
    }
  }

  &.--label-right {
    .label {
      order: 2;
    }
    .toggle {
      order: 1;
    }
  }
}

/* ---------------------------------------------------
   Input Dates col with Calendar Icon
----------------------------------------------------- */

.search-filter-date {
  .col-date {
    &:nth-child(1) {
      padding-right: $gutter-mini;
    }

    &:nth-child(2) {
      padding: 0;
    }

    &:nth-child(3) {
      align-self: flex-end;
      margin: 0 0 $gutter-mini $gutter-mini;
      padding-left: 0;
    }

    // mobile //
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

/* ---------------------------------------------------
    Checkbox with Img and description
----------------------------------------------------- */

.checkbox-img {
  vertical-align: middle;

  // checkbox //
  .round {
    display: inline-block;
    padding-left: $gutter-sm * 2;
  }

  .checkbox .checkmark {
    top: calc($gutter/2);
  }

  // File img //
  .img {
    display: inline-block;
    height: auto;
    margin-right: calc($gutter/2);
    vertical-align: middle;
    width: 40px;
  }

  // File type //
  .description {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;

    .text-paragraph {
      display: block;
    }

    .text-header.h3 {
      margin: 0;
    }
  }
}

.input-with-label {
  @extend .container-bg-white-border;
  padding: 8px $gutter-sm;
  display: flex;
  align-items: center;

  .icon {
    margin: $gutter-mini;
  }

  input {
    color: $color-txt-sec-no-focus;
    flex-grow: 1;
  }
  select {
    color: $color-txt-sec-no-focus;
    flex-grow: 1;
  }
}

/* ---------------------------------------------------
    Select with Button
----------------------------------------------------- */

.select-with-button {
  display: flex;
  flex-direction: row;

  select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    flex-grow: 1;
    height: $button-height;
    margin-right: $gutter-mini;
  }
}

/* ---------------------------------------------------
   Input with only a single value
----------------------------------------------------- */

.input-code {
  input {
    min-height: 70px;
    width: 100%;

    font-size: $font-size-18;
    padding: calc($gutter - 2px);
  }
}
