/* ---------------------------------------------------
    Summary Information in mobile
----------------------------------------------------- */

.summary-number {
  @extend .border-light;
  border-radius: ix-prop('br', 'large');
  color: ix-color('gray-600');
  font-size: $font-size-12;
  margin-bottom: calc($gutter/2);
  padding: $gutter-mini calc($gutter/2);
  text-align: center;
}

/* ---------------------------------------------------
   Badge
----------------------------------------------------- */

.badge {
  @extend .bold;
  @extend .uppercase;
  border-radius: ix-prop('br');
  color: $color-txt-white;
  letter-spacing: 0.3px;
  padding: 8px 15px;
  width: fit-content;
}
