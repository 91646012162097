.search-filter {
  position: relative;

  .header {
    @extend .border-light;
    @extend .prevent-selection;
    box-sizing: border-box;
    background-color: ix-color('ix-white');
    border-radius: ix-prop('br');
    color: $color-txt-sec-focus;
    cursor: pointer;
    display: inline-block;
    padding: calc($button-filter-pad-top/2) calc($button-pad-side * 1.5)
      calc($button-filter-pad-top/1.5) calc($button-pad-side/1.5);
    position: relative;

    &:after {
      @include transition(0.2s);
      color: $color-txt-sec;
      content: '\25be';
      position: absolute;
      right: calc($button-pad-side/2);
      top: calc($button-filter-pad-top/2);
    }

    span.name {
      @extend .text-label;
      @extend .--tiny;
      margin-bottom: 0 !important;

      // Small Desktop //
      @include media-breakpoint-only(lg) {
        white-space: nowrap;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    span.result {
      font-size: $font-size-12;

      // Small Desktop //
      @include media-breakpoint-only(lg) {
        white-space: nowrap;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .content-to-hide {
    @extend .bg-ix-white;
    @extend .border-light;
    box-sizing: content-box;
    border-radius: ix-prop('br');
    display: none;
    margin-top: -1px;
    min-width: $dropdown-width;
    padding: $button-pad-side;
    position: absolute;
    z-index: $zindex-dropdown;
  }

  .reset-button {
    float: right;
  }

  .searchfield-icon {
    margin: calc($gutter/2) 0 $gutter-sm 0;
  }

  li {
    display: block;
    margin-bottom: $gutter-sm;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label.checkbox {
    height: fit-content;
  }

  label {
    display: block;
    margin-bottom: $gutter-mini;

    &.text-label {
      font-size: $font-size-12;
    }
  }

  .sep {
    margin: $gutter 0;

    &.no-top-margin {
      margin-top: 0;
    }
  }

  .filter-sep {
    .sep {
      margin-top: 0;
    }
  }

  &.expanded {
    @extend .box-shadow-dropdowns;

    .header {
      border-bottom-color: ix-color('ix-white');
      border-radius: ix-prop('br') ix-prop('br') 0 0;
      color: $color-txt-sec-focus;
      height: $button-filter-height;
      position: absolute;
      z-index: $zindex-dropdown-header;

      &:after {
        @include transition(0.2s);
        transform: rotate(180deg);
        top: 8px;
      }
    }

    .content-to-hide {
      @extend .box-shadow-dropdowns;
      border-radius: 0 ix-prop('br') ix-prop('br') ix-prop('br');
      display: block;
      margin-top: calc(#{$button-filter-height} - 1px);
    }
  }

  &:hover {
    .header {
      .name {
        color: $color-txt-sec-focus;
      }
    }
  }

  // Scroll //
  .scroll {
    margin-top: 5px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;

    &:last-child {
      padding-bottom: 10px;
    }
    // show scrollbars
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ix-color('gray-300');
      border-radius: ix-prop('br');
    }
  }

  .select-container {
    margin-top: $gutter;

    label {
      margin-bottom: $gutter-mini;
    }

    .select {
      @extend .--small;
      width: 100%;
    }
  }

  // Favorites //
  &.favorites {
    box-shadow: none;
    height: 100%;

    .header {
      @extend .button-icon;
      box-sizing: border-box;
      font-size: $font-size-12;
      min-height: 100%;
      padding: calc($button-pad-side/1.6) $button-pad-side $button-pad-top
        calc($button-pad-side/1.5);

      &:after {
        top: calc($button-pad-side/1.6);
      }

      &:hover {
        .icon {
          color: $color-brand-hover;
        }
      }

      .icon {
        color: $color-txt-interactive;
        font-size: $font-size-14;
        vertical-align: -0.2em;
      }
    }

    .fav-header {
      @extend .bold;
      font-size: $font-size-16;
    }

    li {
      cursor: pointer;

      &:hover {
        a {
          @extend .color-brand-primary;
          @include transition;
          padding-left: $gutter-mini;

          &.selected {
            padding-left: 0;
          }
        }
        .title-icon {
          a {
            padding-left: 0;
          }
        }
      }
    }

    .fav-selected {
      margin-bottom: $gutter-sm;
      margin-top: $gutter-sm;
    }

    .fav-options {
      margin-bottom: $gutter;

      .button-simple-icon {
        @extend .d-block;
        height: fit-content;

        &:not(:last-child) {
          margin-bottom: $gutter-sm;
        }
      }
    }

    .button-plain-text {
      @extend .d-block;
    }

    input {
      margin-top: $gutter-sm;
      margin-bottom: $gutter;
    }

    // Temporary fix for double dropdown //
    .content-to-hide {
      &.open {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }
}

/***** Content-to-hide Width and specifications *****/

.search-filter-dates {
  .content-to-hide {
    min-width: $dropdown-width-lg;

    .search-filter-date {
      margin-bottom: $gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.search-filter-other-options {
  .content-to-hide {
    min-width: $dropdown-width-md;
  }

  .values {
    .row {
      .col {
        &:first-child {
          padding-right: $gutter-mini;
        }
        &:last-child {
          padding-left: $gutter-mini;
        }
      }
    }
  }
}
