/* -----------------------------------
  Text Editor & Previewer
-------------------------------------*/

.text-editor {
  flex-direction: column;
  height: 100%;

  // Quill Classes
  .quill {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .ql-container {
      background-color: ix-color('ix-white');
      flex-grow: 1;
      height: auto;
      overflow-y: auto;

      &.ql-disabled {
        background-color: ix-color('gray-100');
        color: ix-color('gray-500');
      }
    }
  }

  #ql-toolbar {
    background-color: ix-color('ix-white');

    .ql-formats {
      label {
        position: absolute;
        margin: 3px 0px 0px 4px;
        font-weight: 500;
      }

      label:focus {
        color: #cccccc;
      }
    }

    .ql-picker.ql-insertCustomTags {
      width: 90px;
    }

    .ql-picker.ql-insertCustomTags1 {
      width: 90px;
    }

    .ql-picker.ql-dropdown {
      width: 90px;
    }

    .ql-insertCustomTags .ql-picker-item:before {
      content: attr(data-label);
      z-index: 100;
    }

    &.ql-disabled {
      background-color: ix-color('gray-100');
      color: ix-color('gray-500');
      user-select: none;
      pointer-events: none;
    }
  }

  // Editor: Draft js classes //
  .notranslate.public-DraftEditor-content {
    @extend .border-light;
    border-radius: ix-prop('br');
    max-height: $modal-editor-input-height;
    min-height: $modal-editor-input-height;
    overflow-y: scroll;
    padding: calc($gutter/2);
    background-color: ix-color('ix-white');
  }

  .public-DraftStyleDefault-block {
    margin: calc($gutter/2) 0;
  }

  // Editor: Our classes //

  .editor {
    margin-bottom: calc($gutter/2);
  }

  .subject {
    align-items: center;
    display: flex;
    margin-bottom: calc($gutter/2);

    span {
      color: $color-txt-sec-no-focus;
      cursor: default;
      margin-left: calc($gutter/2);
      margin-right: $gutter-mini;
    }

    .subject-input {
      background-color: ix-color('ix-white');
      border: none;
      color: $color-txt-sec-no-focus;
      flex-grow: 1;

      &:focus {
        box-shadow: none;
      }
    }
  }

  // Preview View //
  .preview-header {
    display: flex;
    justify-content: space-between;

    .test-email {
      font-size: $font-size-12;
      text-align: right;
      cursor: pointer;

      .icon {
        margin-right: $gutter-mini;

        &.color-gray-base {
          display: none;
        }
      }

      &.--email-sent {
        cursor: not-allowed;

        .icon {
          &.color-gray-base {
            display: inline-block;
          }

          &.color-brand-primary {
            display: none;
          }
        }
      }
    }

    // Mobile //
    @include media-breakpoint-down(sm) {
      justify-content: center;

      .img {
        display: none;
      }

      .test-email {
        text-align: center;
      }
    }
  }

  .email-preview-container {
    display: block;
    margin: calc($gutter/2) -#{calc($gutter/2)} -#{calc($gutter/2)} -#{calc(
        $gutter/2
      )};
    max-height: $modal-preview-height;
    min-height: $modal-preview-height;
    min-width: 100%;
    overflow-y: scroll;
    position: relative;
  }
}

/* -----------------------------------
  Email Template
-------------------------------------*/

.template-email {
  padding: $gutter;
  background: rgb(238, 238, 238); // legacy

  .template-body {
    box-sizing: border-box;
    margin: auto;
    padding: $gutter-lg;
    width: 100%;

    b,
    strong {
      @extend .bold;
    }

    .sep {
      margin: $gutter-lg 0px;
    }

    .button {
      cursor: default;
      display: inline-block;
      margin: $gutter 0;

      &:hover {
        background-color: $color-brand-primary;
      }
    }

    .logo-container {
      text-align: center;

      .logo {
        display: inline-block;
        max-height: 70px;
        max-width: 70%;
        margin-bottom: $gutter;
      }
    }

    @include media-breakpoint-down(md) {
      width: 95%;
    }

    .template-content {
      min-height: 230px;
      line-break: auto;
      word-break: break-word;
    }
  }

  .template-alerts-footer {
    font-size: $font-size-14;
    padding: $gutter-sm;
    background-color: ix-color('brand-300');
    color: ix-color('ix-white');

    span {
      font-size: $font-size-11;
      color: ix-color('ix-white');
    }
  }

  .template-footer {
    padding: $gutter;
  }

  @include media-breakpoint-down(md) {
    padding: $gutter * 2 0;
  }
}

.cc-bcc-links {
  text-align: right;
  min-height: $gutter;

  .button {
    margin-top: $gutter-mini;

    &:not(:last-child) {
      margin-right: calc($gutter/2);
    }
  }
}
