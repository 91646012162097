// Anchor defult style clean //
   a{
	color: inherit;
	text-decoration: inherit;
	font-weight: inherit;
 }
 
 // List defult style clean //
 ul{
	list-style: none;
	margin: 0;
	padding: 0;
 }