/* ---------------------------------------------------
    Button with dropdown behaviour
----------------------------------------------------- */

.button-dropdown {
  position: relative;
  width: 100%;

  .label {
    &.header {
      padding: 0 $button-pad-side 0 0;
      vertical-align: middle;
    }
  }

  &:after {
    @include transition(0.2s);
    content: '\25be';
    position: absolute;
    right: $button-pad-side;
    top: 12px;
  }

  ul {
    display: none;
  }

  &:hover {
    color: $color-brand-hover;
  }

  &.expanded {
    color: $color-txt-sec;

    .content-to-hide {
      @extend .bg-ix-white;
      box-sizing: border-box;
      border-radius: inherit;
      height: auto;
      left: 0;
      padding: inherit;
      position: absolute;
      width: 100%;
      z-index: $zindex-dropdown;

      ul {
        display: block;

        li {
          border-top: 1px solid $color-borders;
          padding: 13px 0;

          a {
            color: $color-txt-primary;
            display: block;
            text-decoration: none;

            &:hover {
              @include list-hover;
              &.selected {
                padding-left: $gutter-mini !important;
              }
            }

            &.selected {
              @extend .color-brand-primary;
              padding-left: $gutter-mini;
            }
          }
        }
      }
    }

    &:after {
      @include transition(0.2s);
      transform: rotate(180deg);
      top: 14px;
    }
  }

  li:empty {
    display: none;
  }

  // Open up //
  &.--open-up {
    &.expanded {
      border-radius: 0 0 ix-prop('br') ix-prop('br');
      padding-bottom: 0;

      .content-to-hide {
        border-radius: ix-prop('br') ix-prop('br') 0 0;
        bottom: $button-height;

        li {
          border-bottom: 1px solid $color-borders;
          border-top: none;
        }
      }
    }
  }

  // Information panel with dropdown behaviour //
  &.help-info-panel {
    background-color: ix-color('ix-white');
    cursor: auto;
    width: 100%;

    .dropdown-header {
      color: $color-txt-sec-focus;
      cursor: pointer;
      font-size: $font-size-14;
      padding: 0;

      .icon {
        color: $color-txt-interactive;
        left: $gutter;
        position: absolute;
        top: $gutter;
      }

      .label {
        @extend .bold;
        display: block;
        font-size: $font-size-14;
        padding: 4px $gutter 4px $gutter;
      }

      &:after {
        @include transition(0.2s);
        color: $color-txt-interactive;
        content: '\25be';
        position: absolute;
        right: $button-pad-side;
        top: 18px;
      }
    }

    &.expanded {
      .dropdown-header {
        &:after {
          @include transition(0.2s);
          transform: rotate(180deg);
          top: 20px;
        }
      }
    }

    .content-to-hide {
      ul {
        border-top: 1px solid $color-gray-background;

        li {
          @extend .font-regular;
          color: $color-txt-sec-no-focus;
          border-top: none;
          font-size: $font-size-13;
          line-height: $font-size-18;
          .title {
            color: $color-txt-primary;
            margin-bottom: $gutter-mini;
          }

          a {
            color: $color-txt-selected;
            margin-top: $gutter-mini;

            &:hover {
              padding-left: none;
            }
          }

          .paragraph {
            margin-top: calc($gutter/2);
          }
        }
      }
    }

    &:after {
      content: none;
    }

    &:hover {
      color: $color-txt-primary;
    }
  }

  // Simple Gray Outlined Styled //
  &.outline-simple {
    @extend .border-light;
    @extend .font-regular;
    background: ix-color('ix-white');
    border-radius: 0;
    color: $color-txt-primary;
    display: inline-block;
    min-height: auto;
    padding: 0;

    &:after {
      right: 10px;
      top: 4px;
    }

    .label {
      padding: $gutter-mini $gutter-lg $gutter-mini calc($gutter/2);
    }

    .content-to-hide {
      @extend .box-shadow-dropdowns;
      @extend .border-light;
      margin-left: -1px;
      min-width: calc(100% + 2px);
      padding: 0;
      width: fit-content;

      ul {
        li {
          border: none;
          padding: 0;

          div {
            padding: calc($gutter/2) $gutter;
          }

          &:hover {
            background: ix-color('gray-200');
          }
        }
      }
    }
  }

  // Button Secondary Styled //
  &.button-secondary {
    .content-to-hide {
      @extend .box-shadow-dropdowns;
    }
  }
}

/* ---------------------------------------------------
    Simple Icon Button
----------------------------------------------------- */

.button-simple-icon {
  background-color: transparent;
  color: $color-txt-interactive;
  font-size: $font-size-12;
  min-height: calc($button-height/2);
  padding: 0;

  .icon {
    color: $color-txt-sec-no-focus;
    margin-right: $gutter-mini;
  }

  &:hover {
    color: $color-brand-hover;
  }

  // Big in brand primary color //
  &.--big {
    @extend .bold;
    font-size: $font-size-14;

    .icon {
      color: $color-txt-interactive;
      font-size: $font-size-14;
      margin-right: calc($gutter/2);
    }
  }

  // Small gray //
  &.--secondary {
    color: $color-txt-white;
    font-size: $font-size-14;

    .icon {
      color: $color-txt-sec-no-focus;
    }

    &:hover {
      &:not([disabled]) {
        color: $color-txt-interactive;
      }
    }
  }
}

// For all buttons with icons //
.button-icon {
  .icon {
    margin-right: $gutter-mini;
  }
}

/* ---------------------------------------------------
    Icon by itself Button 
  ----------------------------------------------------- */

.button-icon-self {
  padding: calc($gutter/2) $gutter;

  &.vertical-sep {
    border-left: 1px solid $color-borders;
    border-radius: 0;
  }

  .icon {
    color: $color-txt-interactive;
    font-size: $font-size-20;

    &.color-gray-base {
      color: $color-txt-sec-no-focus;
    }
  }

  &:hover {
    .icon {
      color: $color-txt-sec;
    }
  }
}

/* ---------------------------------------------------
    Button with Image
  ----------------------------------------------------- */

.button-with-image {
  background: $color-gray-background;
  border-radius: ix-prop('br');
  border: 1px solid $color-borders;
  display: block;
  padding: $gutter * 2;
  text-align: center;
  width: fit-content;

  .img {
    margin-bottom: $gutter;
  }
}
