body,
html {
	@extend .font-regular;
	background-color: $color-bg-ix;
	font-size: $font-size-init;
	line-height: normal; 
	min-height: 100%;
}


.full-height-in-mobile {
	@include media-breakpoint-down(md) {
		height: 100%;
		&.--logo-circle {
			.logo-circle {
				display: none;
			}
		}
	}
}