/* -----------------------------------
    IE Support Screen
-------------------------------------*/

.container-ie-warning {
  height: 100%;
  z-index: $zindex-popover;

  .container-image {
    @extend .bg-ix-white;
    padding: $gutter * 2;
    height: 100%;
    width: 50%;
    position: fixed;
    top: 0;
    left: 0;

    .img {
      height: auto;
      left: 50%;
      max-width: 650px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }
  }

  .container-information {
    background: $color-bg-ix;
    height: 100%;
    padding: $gutter * 2;
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;

    .content {
      left: 40%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      .text-paragraph {
        &:nth-child(3) {
          margin-bottom: $gutter * 2;
        }
      }

      .h1 {
        font-weight: bold;
        margin-bottom: $gutter;
      }

      .button {
        display: inline-block;
        margin-top: $gutter;
      }
    }
  }

  // MOBILE //
  @include media-breakpoint-down(sm) {
    .container-image {
      width: 35%;
    }
    .container-information {
      width: 65%;
    }
  }
}

/* ---------------------------------------------------
  First Document Screen
----------------------------------------------------- */

.first-document-container {
  @extend .bg-ix-white;
  border-radius: ix-prop('br');
  height: 100%;
  padding: $gutter-lg * 2 $gutter;
  text-align: center;
  width: 100%;

  .img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 200px;
    padding: calc($gutter/2);
  }

  .button {
    display: inline-block;
    padding-left: calc($gutter * 2);
    padding-right: calc($gutter * 2);
    line-height: $button-height;
  }

  .text-paragraph {
    margin-top: $gutter-lg;
  }

  .email {
    color: $color-txt-interactive;
  }
}

/* ---------------------------------------------------
  No Search Results
----------------------------------------------------- */

.no-search-results {
  @extend .bg-ix-white;
  border-radius: ix-prop('br');
  min-height: $list-table-height;
  padding: $gutter * 2 $gutter-lg;
  position: relative;

  &.--card-not-found {
    min-height: 180px;

    .text-header {
      max-width: 100% !important;
    }
  }

  @include media-breakpoint-down(sm) {
    min-height: $list-table-mobile-height;
  }

  .no-results-content {
    .text-header {
      position: relative;
      font-size: $font-size-22;
      // MOBILE //
      @include media-breakpoint-up(md) {
        max-width: 350px;
      }
    }

    .text-paragraph {
      margin: $gutter 0;
      color: $color-txt-primary;
      white-space: pre-line;
    }
    // MOBILE //
    @include media-breakpoint-up(md) {
      max-width: 70%;
    }
  }

  .img {
    position: absolute;

    &.sitting-guy {
      bottom: 0;
      min-height: 100%;
      right: 0;
      width: auto;
      // MOBILE //
      @include media-breakpoint-down(sm) {
        display: block;
        position: initial;
        margin: 0 auto;
        margin-bottom: -#{$gutter * 2};
        margin-right: -#{$gutter-lg};
      }
    }

    &.curved-arrow {
      right: -#{$gutter-sm};
      top: $gutter-mini;
      // MOBILE //
      @include media-breakpoint-down(sm) {
        display: block;
        float: right;
        position: initial;
      }
    }
  }
}

/* ---------------------------------------------------
 Feature Not Avaliable Screen
----------------------------------------------------- */

.not-avaliable-screen {
  height: 100%;
  padding: $gutter-lg * 2 $gutter;
  text-align: center;
  width: 100%;

  .img {
    display: block;
    height: auto;
    max-width: 200px;
    margin: $gutter-lg auto;
  }

  .button {
    margin: $gutter;
  }
}
/* -----------------------------------
    Logo Preview Screen
-------------------------------------*/

.document-preview {
  @extend .container-bg-white-border;
  background-color: ix-color('gray-200');

  .document-page {
    margin: 0 $gutter-sm * 4;
    background-color: white;
    padding: $gutter-sm * 2 $gutter * 3;
    line-height: $font-size-19;
    color: $color-txt-sec-focus;

    .logo {
      margin-bottom: calc($gutter/2);

      .img {
        max-height: 80px;
        max-width: 250px;
      }
    }
  }

  .dummy-data {
    padding-left: 60px;
  }

  @include media-breakpoint-down(sm) {
    .document-page {
      margin: $gutter 0 0 $gutter;
      padding-top: $gutter * 3;
    }

    .dummy-data {
      display: none;
    }
  }
}
