/* -----------------------------------
  Modal Container
-------------------------------------*/

.modal-container {
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-modal;

  // Moval over //
  .modal-over {
    z-index: $zindex-modal-over;
  }
}

/* -----------------------------------
  Modals Contents
-------------------------------------*/

.modal-content-container {
  @extend .bg-ix-white;
  @extend .box-shadow-focus;
  @extend .container;
  border-radius: ix-prop('br');
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  // Content size //
  &.--big {
    height: $modal-height-lg;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      height: $modal-height-lg * 1.3;
    }

    @include media-breakpoint-down(xs) {
      height: 100%;
      min-width: 100%;
    }

    // Content //
    .modal-content {
      padding: calc($gutter * 2);
      border-radius: ix-prop('br');

      @include media-breakpoint-down(xs) {
        padding: $gutter;
        padding-top: calc($gutter * 2);
      }

      .main-content {
        padding: calc($gutter/2) $gutter-sm;
      }
    }
  }

  &.--small {
    width: $modal-width-sm;
    max-width: 100%;

    // Content //
    .modal-content {
      border-radius: ix-prop('br');
      padding: calc($gutter * 2);
    }
  }

  &.--medium {
    width: $modal-width-md;

    .modal-content {
      border-radius: ix-prop('br');
      padding: calc($gutter-sm * 2);
    }
  }

  &.--logo-circle {
    padding-top: calc($gutter/2);
  }

  // Close button //
  .close-icon {
    color: $color-txt-sec;
    height: 15px;
    position: absolute;
    right: $gutter;
    top: $gutter;
    width: 15px;
    z-index: 1;

    &:hover {
      cursor: pointer;
      color: $color-txt-sec-focus;
    }
  }

  .modal-header {
    padding: 0;
    margin-bottom: calc($gutter/2);

    .text-header {
      margin-bottom: calc($gutter/2);
    }

    p {
      margin: calc($gutter/2) 0 0 0;
    }
  }

  .loading {
    position: absolute;
  }

  // logo circle in header //
  .logo-circle {
    @extend .bg-ix-white;
    @extend .box-shadow-focus;
    border-radius: 50%;
    height: 80px;
    left: 50%;
    margin-right: -50%;
    position: absolute;
    top: -40px;
    transform: translate(-50%);
    width: 80px;
    z-index: $zindex-popover;

    display: flex;
    align-items: center;

    .ix-loading {
      display: none;
    }

    .icon {
      width: 80px;
      height: 45px;

      color: $color-txt-interactive;

      &.error {
        color: ix-color('red-300');
      }
    }

    &.loading {
      .ix-loading {
        display: block;
      }

      .ix-logo {
        display: none;
      }
    }
  }

  .radio-buttons-container {
    margin: $gutter-mini 0;

    .checkbox {
      &:not(:only-child) {
        margin: $gutter 0;
      }
    }
  }

  .checkbox {
    &.margin-double-gutter {
      margin: $gutter-sm * 2 0;
    }
  }

  .inputs-container {
    input {
      margin-bottom: $gutter-sm;
    }
  }

  .check-info {
    .icon {
      margin-right: $gutter-mini;
    }
  }

  .buttons-container {
    margin-top: $gutter-lg;

    .col {
      &.padding-xs {
        &:first-child {
          padding-right: $gutter-mini;
        }
        &:not(:first-child) {
          padding-left: $gutter-mini;
        }
      }
    }

    .margin-xs {
      &:not(:last-child) {
        margin-right: $gutter-mini;
      }
    }
  }

  /* -----------------------------------
      Export Modal
    -------------------------------------*/

  .--export-options {
    @extend .bg-ix-white;

    .export-options-header {
      margin-bottom: $gutter;

      .text-header {
        margin: 0;
      }
    }

    .next {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .modal-header {
      padding: 0 15px;
    }

    .col-3 {
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: $gutter;
      }
    }

    .description {
      .bold {
        margin: 0;
      }
    }
  }

  // Alerts //
  .alert {
    margin-top: $gutter-sm;

    &:not(:last-child) {
      margin-bottom: $gutter-sm;
    }
  }

  .text-header {
    margin-bottom: $gutter;
  }

  .input-info {
    margin-top: $gutter-mini;
    margin-bottom: $gutter;

    a {
      color: $color-txt-selected;
      text-decoration: underline;
    }
  }

  // scrools
  .modal-scroll {
    height: fit-content;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /* -----------------------------------
    Confirm Action Modal
  -------------------------------------*/

  .--confirmation {
    text-align: center;
    z-index: $zindex-modal-over;

    // Bottons Margin //
    .button {
      margin-top: $gutter;

      &:not(:last-child) {
        margin-right: $gutter;
      }
    }

    p {
      font-size: $font-size-14;
      line-height: $line-height-regular;
      margin: 0;
      margin-top: calc($gutter/2);
      @extend .bold;
      @extend .text-paragraph;

      &.bold {
        margin: $gutter 0 calc($gutter/2) 0;
      }
    }

    // Bulk Confirmation Window //
    &.--bulk {
      .text-header {
        @extend .bold;
        margin: 10px 0;
      }

      p {
        @extend .text-paragraph;
        font-size: $font-size-14;
      }

      .button {
        min-width: calc(50% - 10px);
      }

      .alert {
        margin-bottom: 0;

        p {
          color: inherit;
        }
      }
    }
  }

  /* -----------------------------------
    Bulk Email Modal
  -------------------------------------*/

  &.--big {
    .--bulk-email {
      padding-bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      .status-bar {
        .buttons-container {
          margin: 0;
        }
      }

      // Middle content
      .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;

        // Text Editor and preview //
        .col-editor {
          @extend .border-dark;
          border-radius: ix-prop('br');
          height: 360px;
          overflow: hidden;
          padding: calc($gutter/2);
        }

        // Information //
        .col-info {
          padding-right: 0;

          .button-dropdown {
            height: auto;
            font-size: $font-size-12;

            li {
              padding: calc($gutter/2) 0 0 0;
            }
          }

          .alert-success {
            font-size: $font-size-12;
          }

          .alert {
            margin: 0;
            text-align: left;
          }
        }
      }

      // Mobile //

      @include media-breakpoint-down(md) {
        .col-info {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
          padding-top: $gutter-sm;
        }

        .alert-warning {
          &.expanded {
            border-radius: 0 0 ix-prop('br') ix-prop('br');
            padding-bottom: 0;

            .content-to-hide {
              border-radius: ix-prop('br') ix-prop('br') 0 0;
              bottom: $button-height;

              li:first-child {
                border-top: none;
              }
              li:last-child {
                border-bottom: 1px solid $color-borders;
              }
            }
          }
        }
      }
    }
  }
}

/* -----------------------------------
    Sequences
  -------------------------------------*/

.sequence-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .check-info {
    font-size: $font-size-12;
  }

  .registration-label {
    font-size: $font-size-10;

    &.status-color-inactive {
      @extend .font-regular;
    }
  }

  .icon {
    margin-right: 2px;
    font-size: $font-size-12;
  }
}

.sequence-documents-types {
  margin: $gutter-sm * 2 0;

  .documents-types-list {
    color: $color-txt-sec-no-focus;
    columns: 150px 2;
    font-size: $font-size-12;
    margin: calc($gutter/2) 0px;

    li {
      margin: calc($gutter/2) 0;

      .icon {
        margin-right: $gutter-mini;
        font-size: $font-size-12;
      }

      &:first-child {
        margin-top: 0;
      }

      .text-with-state {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        flex-direction: row;
        align-items: center;
        padding: 8px 0;
        color: $color-txt-primary;

        .balloon {
          position: absolute;
          padding: 3px 6px;
          min-height: auto;
          left: 110%;
          margin: 0;
          top: 0;
          font-size: $font-size-10;

          &:after {
            content: '';
            position: absolute;
            left: -3px;
            height: 5px;
            width: 5px;
            background: inherit;
            transform: rotate(45deg);
            top: 8px;
          }
        }

        .registration-code {
          color: $color-txt-sec-no-focus;
          position: relative;
        }

        .button-plain-text {
          padding: 0px $gutter-mini;
          min-height: auto;
        }

        &.active {
          @extend .bold;
          .fa-check {
            display: inline-block;
          }
          .fa-xmark {
            display: none;
          }
        }

        &.inactive {
          @extend .font-regular;
          .fa-check {
            display: none;
          }
          .fa-xmark {
            display: inline-block;
            min-width: 12px;
            min-height: 12px;
          }
        }
      }
    }
  }

  a {
    font-size: $font-size-12;
  }
}

/* -----------------------------------
    Plugins
  -------------------------------------*/
.--plugins {
  .text-paragraph  {
    font-size: $font-size-12;
  }

  .modal-content-container .text-header {
    margin-bottom: 0;
  }
  .header-logo {
    padding-right: calc($gutter/2);
    img {
      width: 40px;
    }
  }

  .sub-header {
    display: flex;
    margin-top: $gutter-mini;
    margin-bottom: $gutter;
    position: relative;

    .status-label {
      position: absolute;
      right: 0;
      top: 0;
    }
    .text-paragraph {
      margin-top: 0;
    }
  }

  .d-block-buttons {
    .button {
      &:not(:last-child) {
        margin-bottom: calc($gutter/2);
      }

      &.font-regular {
        @extend .font-regular;
      }
    }
  }

  .modal-content-container {
    .alert {
      margin-top: calc($gutter/2);
    }
    .alert.balloon {
      margin-top: 0;
    }
    .alert-error {
      margin-bottom: $gutter;
    }
  }

  .inputs-container {
    margin: $gutter 0;
    &:first-child {
      margin-top: 0;
    }

    .text-label {
      font-size: $font-size-12;
    }
  }

  .text-to-copy-block {
    margin: $gutter 0;
  }

  .text-paragraph.--small {
    @extend .color-gray-light;
  }
}

/* -----------------------------------
    Two Factor Authentication
  -------------------------------------*/
.--two-factor {
  display: flex;
  justify-content: center;

  .input-code-container {
    margin-left: calc($gutter-mini * 2);

    input {
      text-align: center;
    }
  }
}

/* -----------------------------------
  Handle Call Result Modal
-------------------------------------*/
.handle-call-result-modal {
  &.modal-container {
    .modal-content-container {
      min-height: 170px;
      padding-top: 0;

      .modal-content {
        margin: 0;
        padding: 40px 0 0 0;
      }
    }
  }
}
