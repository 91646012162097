/* -----------------------------------
  Main Content
-------------------------------------*/

#main-content {
  padding: 0;

  .container {
    box-sizing: border-box;
  }

  .content-block {
    display: block;

    &.--block-button {
      .button {
        @extend .d-block;
      }
    }
  }

  .title-block {
    margin-bottom: $gutter;
    // padding-left: $gutter-mini;
  }

  .table-options-block {
    @extend .margin-top;
  }

  .pagination-block {
    @extend .margin-top;
    @extend .justify-content-center;
  }

  .dropdown-button-block {
    @extend .d-flex;
    @extend .margin-bottom;
    margin-top: calc($gutter/2);
    justify-content: center;

    .button-dropdown {
      li {
        &:first-child {
          padding: 0;
        }
        a {
          font-size: $font-size-14;
        }
      }
    }
  }

  .at-configuration-block {
    margin: $gutter 0;

    .text-header {
      margin: $gutter-mini 0;
    }
  }

  .summary-table-block,
  .information-block {
    position: relative;
    min-height: $summary-table-height;

    .help-info-panel {
      display: block;
      margin-top: $gutter;
      height: auto;

      .content-to-hide {
        position: relative;
        width: calc(100% + #{$gutter * 2});
        margin-left: -#{$gutter};
      }
    }
  }

  .list-table-block {
    min-height: $list-table-height;
    position: relative;
  }

  .multiple-buttons-block {
    margin-bottom: $gutter;

    .button {
      &:not(:last-child) {
        margin-bottom: calc($gutter/2);
      }
    }
  }

  /* -----------------------------------
    Mobile only Elements
  -------------------------------------*/

  .mobile-buttons-block {
    display: none;

    .button-outline {
      margin-right: $gutter-mini;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .summary-number {
    display: none;

    @include media-breakpoint-down(md) {
      display: inline-block;
      right: $gutter-sm;
    }
  }

  /* -----------------------------------
  Main Content: Mobile
-------------------------------------*/

  @include media-breakpoint-down(md) {
    .title-block {
      .h1 {
        font-size: $font-size-22;
      }
    }

    .box-shadow-dropdowns,
    .search-filter.expanded,
    .search-filter.expanded .content-to-hide {
      box-shadow: none;
    }

    // clean table options when empty //
    .mobile-empty {
      min-height: 0;
    }

    // Filters //
    .search-filters-row {
      .col {
        padding-left: $gutter-mini;
        padding-right: $gutter-mini;
        padding-top: $gutter;
      }

      .content-to-hide {
        margin-top: -1px;
        position: relative;
        padding-top: $button-filter-height;
        min-width: 100%;
      }
    }

    .search-filter-date {
      margin: -#{$gutter} calc($gutter/2) calc($gutter-sm * 2) calc($gutter/2);

      label {
        margin-left: -#{calc($gutter/2)};
        margin-bottom: -#{$gutter-sm};
        margin-top: $gutter * 2;
      }

      .input-dates {
        margin-top: 0;

        label {
          margin-left: 0;
          margin-bottom: $gutter-mini;
          margin-top: 0;
        }
      }

      .col-date:nth-child(3) {
        display: none;
      }
    }

    .values {
      .row {
        &:not(:first-child) {
          padding: 0 calc($gutter/2);
        }
        .col {
          padding-top: 0;
        }
      }
    }

    // Favorites //
    .favorites {
      .header {
        min-width: auto;

        span {
          display: none;
        }

        &:after {
          content: none;
        }
      }

      &.expanded {
        .header {
          border-bottom: 1px solid $color-borders;
          border-radius: ix-prop('br');
          height: auto;
        }
      }

      .title-icon {
        .icon {
          color: $color-txt-sec-focus;
        }

        a:not(:last-child) {
          margin: 0 $gutter;
        }
      }

      input {
        height: $button-height;
      }

      .button-primary,
      .button-plain-text {
        height: $button-height;
        margin: calc($gutter/2) 0;
      }
    }

    // Search Options //
    .table-options {
      display: inline-block;
      width: 100%;

      .select-col {
        // hard reset button //
        .button {
          bottom: 48px;
          position: absolute;
          right: -#{$gutter-sm};
        }
      }
    }

    .options-col {
      display: none;

      .option {
        border-bottom: 1px solid $color-borders;
        display: block;
        padding: $gutter-sm 0;
        text-align: left;

        .header {
          width: 100%;
        }

        .content-to-hide {
          margin: 0 1px 0 -1px;
          min-width: 100%;
          position: relative;
        }

        &:first-child {
          .content-to-hide {
            .d-flex {
              justify-content: space-between;
            }

            .button-sort {
              padding: 0 $gutter-sm * 2 * 2;
            }
          }
        }

        &.mobile-filter {
          border-bottom: none;
        }

        .label-toggle {
          border: none;
          padding: $gutter-mini 0;

          .toggle {
            position: absolute;
            right: 0;
          }
        }
      }

      .table-options-dropdown .header:after {
        right: calc($gutter/2);
      }
    }

    // New Document butttons //
    .buttons-block {
      display: none;
    }

    // Sumarry Table //
    .summary-table-block {
      display: none;
    }

    // Select Page //
    .select-col {
      .checkbox {
        font-family: 'Oxygen-bold';
        font-size: $font-size-20;
        padding-left: $gutter-lg;
      }

      .checkmark {
        top: $gutter-mini;
      }
    }
  }

  /* -----------------------------------
    Mobile Side Container
  -------------------------------------*/

  //** Side container **//
  .mobile-side-container {
    @include transition(
      0.4s
    ); // LEGACY: after the removal of the legacy mobile menus should be 0.3
    background-color: $color-bg-ix;
    display: block;
    height: 100%;
    left: calc(
      100% + 266px
    ); // LEGACY: after the removal of the legacy mobile menus should be 100%
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zindex-fixed;
    padding: 0;

    &.open {
      left: 0;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .mobile-side-content {
    @include media-breakpoint-down(md) {
      @include transition(
        0.4s
      ); // LEGACY: after the removal of the legacy mobile menus should be 0.3
      background: transparent;
      border: none;
      box-shadow: none;
      display: block;
      height: 100%;
      left: calc(
        100% + 266px
      ); // LEGACY: after the removal of the legacy mobile menus should be 100%
      margin: $button-filter-height 0 0 0;
      overflow-y: scroll;
      padding: $gutter;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $zindex-fixed-content;

      &.open {
        left: 0;
      }
      // Exceptions //
      &.buttons-block {
        padding: $gutter $gutter * 2;

        .label {
          font-size: $font-size-14;
        }

        a {
          font-size: $font-size-14;
        }
      }
    }
  }

  // Temporary button in legacy mobile header //

  .legacy-header-button {
    display: none;

    @include media-breakpoint-down(md) {
      cursor: pointer;
      display: block;
      height: 49px;
      position: absolute;
      right: -30px;
      top: 0;
      width: 25%;
    }
    @include media-breakpoint-down(sm) {
      right: 0px;
    }
  }

  /* -----------------------------------
    Other Device Sizes
  -------------------------------------*/

  //** Small Mobile **//

  @include media-breakpoint-down(sm) {
    .list-table-block {
      margin: 0 -#{calc($gutter-sm / 2)};
    }

    .options-dropdown {
      .content-to-hide {
        min-width: calc(100% - calc($gutter-sm / 2));
      }
    }
  }

  //** Small Desktop **//
  @include media-breakpoint-only(lg) {
    .dropdown-button-block {
      .button-dropdown {
        .label {
          font-size: $font-size-12;
        }
      }
    }
  }

  /* -----------------------------------
  Sequences
-------------------------------------*/

  &.--sequences {
    .filters-row {
      margin-bottom: $gutter;
    }

    .table-options-block {
      padding-right: 0;
    }

    @include media-breakpoint-down(md) {
      #globalReset {
        bottom: 0;
      }
      .filters-row {
        margin: 0;
      }

      .mobile-buttons-block {
        margin-top: -20px;
        margin-bottom: $gutter;
      }
    }
  }

  /* -----------------------------------
  Open Account
-------------------------------------*/

  &.--suppliers {
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      .summary-table-block {
        padding: 0 calc($gutter/2);

        .button {
          width: 100%;
        }
      }
    }

    #globalReset {
      margin-left: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 0 $gutter;

      .search-options-block {
        .search-options {
          .select-col {
            .button {
              bottom: 44px;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .card .status {
        margin: 0;
      }
    }
  }
}

.open-account-container {
  @extend .hide-scroll;
  flex-direction: column;
  display: flex;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

/* -----------------------------------
  Settings: IX Labs & Plugins
-------------------------------------*/

.--labs {
  .header-block {
    max-width: $card-max-width;
    text-align: center;
    margin: $gutter * 2 auto;

    p {
      color: $color-txt-sec-focus;
    }

    .img {
      max-width: $pageheader-img-width;
      max-height: $pageheader-img-height;
      margin-bottom: $gutter-mini;
    }

    .text-header {
      margin-bottom: $gutter-sm;
    }
  }

  .sep-block {
    margin: $gutter * 2 0;
  }

  .portal-block {
    text-align: center;

    p {
      color: $color-txt-sec-focus;
      margin-bottom: $gutter;
    }

    iframe {
      height: 100vh;
    }

    .text-header {
      margin-bottom: $gutter-sm;
    }
  }
}

.--plugins {
  @extend .--labs;
  .header-block {
    max-width: 800px !important;
    justify-content: center !important;
  }
}

/* -----------------------------------
  Settings Layout
-------------------------------------*/

.--settings {
  margin-bottom: $toolbar-height;

  .content-block {
    margin-bottom: $gutter * 2;
  }

  .text-header {
    margin-bottom: calc($gutter/2);
  }

  .form-container {
    margin: $gutter 0;

    select,
    input {
      width: 100%;
      min-height: 40px;
    }

    textarea {
      width: 100%;
      min-width: 100%;
    }

    .form-row {
      margin-bottom: $gutter-lg;

      @include media-breakpoint-down(sm) {
        .col-sm-6 {
          &:not(:last-child) {
            margin-bottom: $gutter-lg;
          }
        }

        .col-sm-3,
        .col-sm-6,
        .col-sm-9 {
          padding: 0 !important;
        }

        &.dependent {
          .col-sm-6,
          .col-sm-3 {
            margin-bottom: $gutter-mini;
          }
        }
      }
    }
  }

  .page-header-block {
    margin-bottom: $gutter;
  }

  .controller-block {
    @extend .d-flex-row;
    align-items: center;

    .select-col {
      margin-right: $gutter;
      width: 40%;
      .select {
        min-width: 100%;
      }
    }

    .description-col {
      flex-grow: 1;
    }

    .text-paragraph {
      margin: 0;
    }

    .text-paragraph {
      margin: 0;
    }

    .text-paragraph {
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      .select-col,
      .description-col {
        width: 100%;
        margin: $gutter-mini 0;
      }
    }
  }

  .upload-file-block {
    .document-preview {
      margin-bottom: calc($gutter/2);
    }
    .alert {
      margin-top: calc($gutter/2);
    }
    .button-plain-text {
      margin-left: $gutter;
    }
  }

  .other-input {
    margin-top: $gutter-mini;

    .text-paragraph {
      white-space: nowrap;
      margin-right: calc($gutter/2);
    }
  }

  .notification-area {
    &:empty {
      display: none;
    }

    .alert {
      &:not(:last-child) {
        margin-bottom: $gutter-mini;
      }
    }
  }

  .bank-data-block {
    .form-row {
      margin-bottom: $gutter-sm;

      @include media-breakpoint-down(sm) {
        .other-input {
          margin-top: 1px;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &#main-content {
      padding: 0 $gutter;
    }

    .no-padding-right {
      padding-right: $gutter-sm;
    }
  }
}

/* -----------------------------------
  Contacts Layout
-------------------------------------*/

.--contacts {
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .mobile-buttons {
    position: absolute;
    top: 0;
    right: $gutter-sm;
  }

  .--mobile-actions {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  .--contact-toggle {
    margin-left: -10px;
  }

  .page-sub-header-block {
    @extend .d-flex-row;
    @extend .justify-content-between;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      // margin-left: $gutter-sm;
      flex-direction: column;
      align-items: flex-start;
      a {
        margin-top: calc($gutter/2);
      }
    }
  }
  .page-header-block .text-header {
    @include media-breakpoint-down(sm) {
      // margin-left: $gutter-sm;
    }
  }

  .input-balance--remaining {
    background-color: ix-color('gray-200');
    color: #000000 !important;
  }

  .migrated-dropdown {
    box-sizing: border-box;
    .content-dropdown {
      position: relative;
    }
  }

  .--actions {
    height: $gutter * 4;
    background-color: ix-color('gray-700');

    .totals {
      .cancel-selection {
        cursor: pointer;
        color: $color-txt-sec-no-focus;
        margin-right: $gutter-mini;
        font-size: $font-size-14;
      }

      // Vertical line //
      &:after {
        content: '';
        border-right: 1px solid ix-color('gray-500');
        height: 74px;
        position: absolute;
        right: 0;
        top: -27px;
      }
    }
  }
}

/* -----------------------------------
  Contact List
-------------------------------------*/

.--contact-list {
  .text-header {
    padding: 0;
  }

  .button-size {
    @extend .button-primary;
    display: none;
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;

    @include media-breakpoint-down(md) {
      display: flex;
    }
  }
}

/* -----------------------------------
  Digital Signature Card Layout
-------------------------------------*/
.--ds-page {
  @extend .--contact-list;

  .text-paragraph {
    white-space: pre-line;
  }

  .--ds-modals {
    white-space: pre-line;

    .--actions {
      button {
        width: 100%;
      }
    }
  }

  .--ds-description {
    a {
      color: ix-color('brand-200');
    }
  }

  &-list-skeleton {
    .react-loading-skeleton {
      margin-top: $gutter-sm;
      height: calc($gutter * 5);
    }

    &-active {
      .react-loading-skeleton {
        height: 100%;
      }
    }
  }
}

/* -----------------------------------
  Digital Signature Card Layout
-------------------------------------*/
.--ds-provider {
  @extend .--contact-list;

  display: flex;
  align-items: center;

  margin-top: $gutter-sm;
  background-color: #ffffff;
  white-space: pre-line;
  border-radius: ix-prop('br', 'base');

  .badge {
    padding: 6px 10px !important;
    font-size: $font-size-10 !important;
  }

  .checkbox {
    height: 100% !important;
  }

  @include media-breakpoint-down(md) {
    padding: $gutter;
  }

  &-content {
    padding: calc($gutter-mini * 2) $gutter 0px 0px;

    @include media-breakpoint-down(md) {
      padding: 0px;
    }
  }

  img {
    width: 80%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .--ds-link {
    color: ix-color('blue-200');
    text-align: end;
    padding-right: $gutter-mini;

    span {
      padding-right: $gutter-mini;
      font-size: $font-size-12;
      color: ix-color('blue-200');
    }
  }

  .--ds-expired {
    @extend .--ds-link;
    color: ix-color('orange-100');

    span {
      padding-left: $gutter-mini;
      color: ix-color('orange-100');
    }
  }

  .--ds-actions {
    display: flex;
    padding: $gutter-sm 0 $gutter-sm;

    .renew-button {
      @extend .button;
      margin-right: $gutter;
      background-color: ix-color('orange-100');

      &:hover {
        background-color: ix-color('yellow-200');
      }

      .label {
        margin-left: $gutter-mini;
        color: ix-color('ix-white');
      }
    }

    span {
      color: ix-color('brand-200');
    }

    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }
}
/* -----------------------------------
  Plugins List
-------------------------------------*/

.--plugins {
  .list-of-image-card {
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .integrations-list {
    ul {
      margin-top: $gutter-sm;
    }
  }
}

/* -----------------------------------
  Integrations Page
-------------------------------------*/
.config-integration {
  @extend .--contacts;

  &--header {
    .image-container {
      width: calc($gutter-sm * 4);

      img {
        width: 100%;
      }
    }
  }

  &--footer {
    button {
      width: 100%;
    }
  }

  .sep-light {
    width: 100% !important;
    margin: $gutter-sm 0px !important;
  }

  .--credentials-copy-url {
    .copy-url--input,
    .copy-random-number {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: $gutter-sm;
      border: 0.5px solid ix-color('gray-300');
      border-radius: 4px;

      &-text {
        word-break: break-all;
      }

      &-action {
        width: 5%;
        font-size: 18px;
        margin: 0 18px;

        cursor: pointer;

        .divider {
          border-left: 1px solid ix-color('gray-300');
          height: $gutter;
          margin-right: $gutter-lg;
        }
      }
    }

    .copy-random-number {
      padding: $gutter-mini $gutter-mini $gutter-mini $gutter-sm;

      .copy-url--input-action {
        width: 20%;
        margin: 0;

        button {
          min-width: 0 !important;
          margin-left: $gutter-sm;
        }
      }
    }
  }

  .form-row {
    @include media-breakpoint-up(md) {
      .col-md-4 {
        &:nth-child(3n + 1) {
          padding-right: calc($gutter-sm / 2) !important;
        }

        &:nth-child(3n + 2) {
          padding-left: calc($gutter-sm / 2) !important;
          padding-right: calc($gutter-sm / 2) !important;
        }

        &:nth-child(3n + 3) {
          padding-left: calc($gutter-sm / 2) !important;
        }
      }

      .--credentials-copy-url.col-md-6 {
        &:nth-child(even) {
          padding-right: calc($gutter-sm / 2) !important;
        }

        &:nth-child(odd) {
          padding-left: calc($gutter-sm / 2) !important;
        }
      }
    }
  }
}

/* -----------------------------------
  Billing Alerts Page
-------------------------------------*/
.--billing-alerts {
  @extend .--contact-list;

  .alert {
    display: flex;
  }

  .button-outline {
    &.--red {
      &:hover {
        color: ix-color('red-300');
        opacity: 0.75;
      }
    }
  }

  .form-container {
    .search-filter {
      height: 50px;

      .header {
        height: 50px;
        width: 100%;
      }

      .content-to-hide {
        // Allow for the 20px padding and 1px border
        width: calc(100% - (20px * 2) - (1px * 2));
      }
    }

    .dropdown-container-variables {
      @extend .dropdown-container;

      .content-to-hide {
        margin-left: -30px !important;
      }
    }
  }

  .--button-wrapper {
    display: flex;
    margin-left: 1px;
  }

  .text-paragraph {
    white-space: pre-line;
  }
}

/* -----------------------------------
  Billing Payments Page
-------------------------------------*/
.--bank-transfer {
  .title {
    margin-bottom: 0;
  }
}

/* -----------------------------------
  Login Page
-------------------------------------*/
.--login {
  @extend .--contact-list;

  input {
    min-height: 44px !important;
  }

  margin-top: calc($gutter-lg * 2);

  &-wrapper {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    padding: 0;
    margin: $gutter-sm;
    border-radius: ix-prop('br');

    .row {
      height: 100%;
      align-items: end;

      .col-12 {
        background-color: transparent !important;
      }
    }

    &-image {
      border-radius: 4px 0px 0px 4px !important;
      padding: $gutter-sm;
      background: url('https://invoicexpress-frontend-preproduction.s3.eu-west-1.amazonaws.com/release-2024-05-02T19%3A05%3A28/media/signup.77fa2031359affd39d04.png')
        no-repeat;
      background-size: cover;

      display: flex;
      align-items: center;

      .--logo {
        width: 50%;
      }
    }

    &-content {
      display: flex;
      align-items: center;
    }
  }

  &-form {
    .form-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      a {
        min-height: 0;
      }
    }
  }

  .--twofactor-wrapper-image {
    display: flex;
    justify-content: center;

    img {
      width: 80%;
    }
  }

  &-flags {
    display: flex;
    justify-content: center;
    margin-left: $gutter-sm;

    .flags-container {
      display: flex;
      justify-content: center;

      img {
        margin: 0 $gutter-mini;
        cursor: pointer;
      }
    }
  }

  .alert {
    display: flex;
    align-items: center;
  }

  .input-icon {
    @extend .input-icon;
    .icon {
      font-size: $font-size-16;
    }
  }
}

/* -----------------------------------
  View Items Page
-------------------------------------*/
.--view-items {
  .mobile-side-container {
    .buttons-block {
      // padding: 20px 10px !important;
    }
  }

  .mobile-side-content {
    .row {
      width: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .page-sub-header-block {
    height: 55px;
  }
}

/* -----------------------------------
  Export SAF-T
-------------------------------------*/
.--export-saft {
  .option-container {
    .checkbox {
      padding-left: 25px;
      margin: 0;
    }
  }

  .period-selector-container {
    .period-selector {
      padding-right: 0;

      @include media-breakpoint-up(sm) {
        padding-right: calc($gutter-sm * 2);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .saft-version {
    white-space: pre;

    .text-paragraph {
      font-size: 0.75rem;
    }
  }

  .modal-content {
    .userEmail {
      color: ix-color('green-100');
    }
  }
}

/* -----------------------------------
  Billing Preferences Page
-------------------------------------*/

.--billing-preferences {
  .form-container {
    .selector-container {
      padding: 0 !important;

      @include media-breakpoint-up(md) {
        &:nth-child(odd) {
          .selector-wrapper {
            padding-right: calc($gutter-sm / 2);
          }
        }

        &:nth-child(even) {
          .selector-wrapper {
            padding-left: calc($gutter-sm / 2);
          }
        }
      }
    }
  }
}

/* -----------------------------------
  Schedule SAF-T
-------------------------------------*/
.--schedule-saft {
  .form-container {
    .label-toggle {
      .label {
        padding-left: 0.5rem;
      }
    }

    .users-wrapper {
      .users-container {
        &:nth-child(even) {
          .users-row {
            padding: 0;

            @include media-breakpoint-up(md) {
              padding-left: calc($gutter-sm/2);
            }
          }
        }

        &:nth-child(odd) {
          .users-row {
            padding: 0;

            @include media-breakpoint-up(md) {
              padding-right: calc($gutter-sm/2);
            }
          }
        }

        .users-row {
          .--manage-user-cell {
            &.disabled {
              color: #8b8e92;
              opacity: 0.5;
              cursor: not-allowed !important;
              border: 0 !important;

              .text-label {
                user-select: none;
                cursor: not-allowed !important;
              }
            }
          }
        }
      }
    }
  }
}

/* -----------------------------------
  Tax Data & Billing Data Pages
-------------------------------------*/

.--tax-data,
.--billing-data {
  .form-container {
    .input-wrapper-all {
      padding-left: $gutter-sm;
      padding-right: $gutter-sm;
    }

    .selector-container {
      padding: 0 !important;

      @include media-breakpoint-up(md) {
        &:nth-child(odd) {
          .selector-wrapper {
            padding-right: calc($gutter-sm / 2);
          }
        }

        &:nth-child(even) {
          .selector-wrapper {
            padding-left: calc($gutter-sm / 2);
          }
        }
      }
    }

    #validate_button {
      height: 40px;
      min-height: 40px;
      max-height: 40px;
    }
  }
}

/* -----------------------------------
  Export Data
-------------------------------------*/
.--export-data {
  .option-container {
    .checkbox {
      padding-left: 25px;
      margin: 0;
    }
  }

  .period-selector-container {
    .period-selector {
      padding-right: 0;

      @include media-breakpoint-up(sm) {
        padding-right: calc($gutter-sm * 2);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .export-disclaimer {
    .text-paragraph {
      font-weight: 900;
      font-size: 1rem;
    }
  }
}

/* -----------------------------------
  Message Templates
-------------------------------------*/
.--message-templates {
  .checkbox {
    width: fit-content !important;

    &.disabled {
      @extend .color-gray-400;
      cursor: not-allowed !important;
    }
  }
}
