/* ---------------------------------------------------
    Process Step Information
----------------------------------------------------- */

.step-container {
  @extend .bold;
  display: inline-block;
  min-width: max-content;

  .step-circle {
    border-radius: 50%;
    border: solid 1px $color-borders-dark;
    color: $color-txt-sec-focus;
    display: inline-block;
    height: $step-circle;
    padding: 3px;
    text-align: center;
    width: $step-circle;

    .icon {
      display: none;
    }
  }

  .label {
    color: $color-txt-sec-focus;
    display: inline-block;
    padding-left: 5px;
  }

  &.checked {
    .step-circle {
      border: 1px solid $color-brand-primary;
    }

    .icon {
      color: $color-txt-interactive;
      display: inline-block;
    }

    .step-num {
      display: none;
    }

    .label {
      color: $color-txt-interactive;
    }
  }

  &.disabled {
    opacity: 0.2;
  }
}

/* ---------------------------------------------------
    Information with a Icon
----------------------------------------------------- */

.icon-info {
  @extend .bold;
  @extend .color-information;
  font-size: $font-size-12;
  line-height: $line-height-sm;

  .icon {
    font-size: $font-size-16;
    margin-right: $gutter-mini;
    vertical-align: -0.25em;
  }
}

/* ---------------------------------------------------
    Status Information
----------------------------------------------------- */

.status-label {
  font-size: $font-size-9;
  text-transform: uppercase;
  width: max-content;

  .icon {
    padding-right: 2px;
    font-size: $font-size-14;
    vertical-align: -0.25em;
  }
}

/* ---------------------------------------------------
    Title with Icon
----------------------------------------------------- */

.header-with-icon {
  margin-bottom: $gutter;

  .icon {
    margin-right: calc($gutter/2);
  }
}

/* ---------------------------------------------------
    Action success
----------------------------------------------------- */

.action-success {
  .icon {
    color: $color-txt-interactive;
    margin-left: calc($gutter/2);
  }
}

/* ---------------------------------------------------
    Text to Copy Block
----------------------------------------------------- */

.text-to-copy-block {
  @extend .container-bg-white-border;
  color: $color-txt-primary;
  font-size: $font-size-12;
  line-break: anywhere;
  padding: 12px;
  padding-right: $gutter * 3;
  position: relative;

  .button {
    background-color: transparent;
    padding: calc($gutter/2) 18px;
    color: $color-txt-primary;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    border-left: 1px solid $color-borders;
    height: calc(100% - 10px);
    margin: $gutter-mini 0;
    font-size: $font-size-14;
  }

  .balloon {
    position: absolute;
    min-height: auto;
    font-size: 0.71rem;
    right: 3px;
    margin-top: 0;
    top: 14px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    background-color: ix-color('ix-white');
  }
}
