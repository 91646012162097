.tab {
  &--list {
    @extend .d-flex;
    border-bottom: 0.5px solid ix-color('gray-300');

    li {
      padding: $gutter-sm;
      margin: 0;
      cursor: pointer;
      transition: background 0.5s;
      
      .text-header {
        color: ix-color('gray-400');
      }
    }

    .tab--active {
      background-color: ix-color('ix-white');
      border-radius: $gutter-mini $gutter-mini 0 0;

      .text-header {
        color: ix-color('brand-200');
      }
    }

  }

  &--loading {
    text-align: center;
    opacity: 80%;
    margin-top: $gutter;
  }
}