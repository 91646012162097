.form-container {
    margin: $gutter 0;
    
    select,
    input {
      width: 100%;
      min-height: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: $gutter-mini;
      }
    }

    textarea {
      width: 100%;
      min-width: 100%;
    }

    .react-date-picker, .react-date-picker__wrapper {
      min-width: 100% !important;
      min-height: auto;
    }
    .react-date-picker__wrapper {
      padding: 4px 0;
      
      input {
        margin-bottom: 0;
      }
    }

    .form-row {
      margin-bottom: $gutter-sm;
      
      @include media-breakpoint-down(sm) {
        .col-sm-6 {
          &:not(:last-child) {
            margin-bottom: $gutter-lg;
          }
        }
        &.dependent {
          .col-sm-6, .col-sm-3 {
            margin-bottom: $gutter-mini
          }
        }
      }

      &.checkbox-row {
        .checkbox {
          margin: $gutter-sm 0;
        }
      }
    }

    @include media-breakpoint-down(xs) {  
      .no-padding-right {
        padding-right: $gutter-sm;
      }
    }

    .no-margim-bottom {
      margin-bottom: 0;
    }
  }