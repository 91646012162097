/* ---------------------------------------------------
   Fonts Family
----------------------------------------------------- */

@import './fonts/Oxygen-Light';
@import './fonts/Oxygen-Regular';
@import './fonts/Oxygen-Bold';

$fonts: (
  regular: 'Oxygen-Regular',
  bold: 'Oxygen-bold',
  light: 'Oxygen-Light',
);

@each $name, $prop in $fonts {
  .font-#{$name} {
    font-family: $prop;
  }
}

/* ---------------------------------------------------
  Typography Scale
----------------------------------------------------- */

$font-size-init: 14px;
$font-size-9: 0.64rem;
$font-size-10: 0.71rem;
$font-size-11: 0.79rem;
$font-size-12: 0.85rem;
$font-size-13: 0.93rem;
$font-size-14: 1rem;
$font-size-16: 1.14rem;
$font-size-17: 1.21rem;
$font-size-18: 1.29rem;
$font-size-19: 1.43rem;
$font-size-20: 1.43rem;
$font-size-22: 1.57rem;
$font-size-24: 1.71rem;
$font-size-28: 2rem;
$font-size-34: 2.43rem;

/* ---------------------------------------------------
  Classes
----------------------------------------------------- */

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}


.text-header {
  @extend .bold;
  color: $color-txt-primary;
  padding: 0;

  &.h1 {
    font-size: $font-size-34;
  }

  &.h2 {
    font-size: $font-size-24;
  }

  &.h3 {
    font-size: $font-size-20;
  }

  &.h4 {
    font-size: $font-size-16;
  }

  &.--secondary{
    @extend .font-regular;
    color: $color-txt-sec;
  }
}

.text-paragraph {
  color: $color-txt-sec-no-focus;
  font-size: $font-size-14;
  line-height: $font-size-20;

  &.--small {
    font-size: $font-size-11;
    line-height: $font-size-16;
  }

  &.--tiny {
    letter-spacing: 0.4px;
    font-size: $font-size-9;
    line-height: $font-size-16;
    color: $color-txt-primary;
    line-height: 14px;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
  
  &.no-margin-top {
    margin-top: 0;
  }
}

.text-label {
  @extend .bold;
  color: $color-txt-sec-no-focus;
  cursor: text;
  display: block;
  font-size: $font-size-14;
  margin-bottom: $gutter-mini;

  &.--small {
    font-size: $font-size-10;
    letter-spacing: 0.3px;
  }

  &.--tiny {
    font-size: $font-size-9;
    line-height: $font-size-14;
    letter-spacing: 0.3px;
  }
}