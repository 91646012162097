//-----------------------
// Side Navigation
//-----------------------

.side-navigation {
  @extend .button;
  cursor: default;
  background-color: ix-color('ix-white');
  
  li {
    &:not(:last-child) {
      border-bottom: 1px solid $color-borders;
      color: $color-txt-sec-focus;
    }
    &:first-child {
      border-bottom: 1px solid $color-borders-dark;
      padding-bottom: $gutter-sm;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    display: block;
    padding: $gutter-sm 0;
    color: $color-txt-sec-no-focus; 
    
    &:hover {
      @extend .bold;
    }
  }

  .selected {
    @extend .bold;
    cursor: default;
  }
}
