/* ---------------------------------------------------
    Global Attributes
----------------------------------------------------- */

input[type='date'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea {
  @extend .font-regular;
  @extend .container-bg-white-border;
  color: $color-txt-primary;
  font-size: $font-size-14;
  min-height: $button-height;
  padding: calc($button-pad-side/2) $button-pad-side;
  box-sizing: border-box;

  &.d-block {
    width: 100%;
  }

  &.--small {
    height: $input-height-sm;
    min-height: $input-height-sm;
    padding: 0 calc($button-pad-side/2);
  }

  &::placeholder {
    color: $color-txt-sec-no-focus;
    opacity: 0.5;
  }

  &:focus {
    @extend .box-shadow-focus;
    outline: none !important;
  }

  &.--clean {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.error {
    border-color: ix-color('red-200');
  }
}

input[type='date'] {
  cursor: pointer;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
}

input:disabled {
  color: $color-txt-sec-no-focus;
  opacity: 0.5;
  cursor: not-allowed;
}

/* ---------------------------------------------------
    Input type File
----------------------------------------------------- */

input[type='file'] {
  display: none;
}

/* ---------------------------------------------------
    CHECKBOX
----------------------------------------------------- */

.checkbox {
  cursor: pointer;
  display: block;
  font-size: $font-size-14;
  height: calc($checkbox-size + 2px);
  padding-left: $gutter;
  position: relative;
  width: auto;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0 !important;

    &:checked ~ .checkmark {
      &:after {
        display: block;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .checkmark {
    @extend .bg-ix-white;
    @extend .border-light;
    border-radius: ix-prop('br', 'small');
    height: $checkbox-size;
    left: 0;
    position: absolute;
    top: 0;
    width: $checkbox-size;

    &:after {
      border-radius: 1px;
      border: solid $color-brand-primary;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 10px;
      left: 5px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg);
      width: 5px;
    }

    &.disabled {
      color: #8b8e92;
      background-color: #f4f5f5;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  // Round checkbox //
  &.round {
    .checkmark {
      border-radius: 50%;

      &:after {
        background-color: $color-brand-primary;
        border-radius: 50%;
        content: '';
        display: none;
        height: 6px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 6px;
      }

      // 'checkmark' bigger version //
      &.--big {
        height: $checkbox-size-lg;
        width: $checkbox-size-lg;

        &:after {
          height: 12px;
          width: 12px;
        }
      }
    }

    // Round checkbox bigger label //
    &.--big {
      height: $checkbox-size-lg;

      label {
        height: $checkbox-size-lg;
      }
    }
  }

  &:hover input ~ .checkmark {
    @extend .bg-gray-200;
  }
}

/* ---------------------------------------------------
    CUSTOM SELECT
----------------------------------------------------- */

select {
  appearance: none;
  color: $color-txt-primary;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  box-sizing: border-box;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.select {
  @extend .container-bg-white-border;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 17px) calc(1em + 2px),
    calc(100% - 12px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  padding: calc($button-pad-side/2) calc($button-pad-side * 2)
    calc($button-pad-side/2) calc($button-pad-side); //.6em 1.4em .5em .8em;

  &:after {
    content: '\25be';
    position: absolute;
    right: $button-pad-side;
    top: 12px;
  }

  &:hover {
    border-color: $color-borders-hover;
  }

  &:focus {
    @extend .box-shadow-focus;
  }

  &.--small {
    border-radius: ix-prop('br', 'small');
    background-size:
      4px 4px,
      4px 4px,
      1px 1em;
    background-position:
      calc(100% - 14px) 1em,
      calc(100% - 10px) 1em,
      calc(100% - 2.5em) 0.5em;
    padding: 5px 40px 5px 10px;
    height: $input-height-sm;
    min-height: $input-height-sm;
  }

  &.--clean {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

select:disabled {
  color: $color-txt-sec-no-focus;
  background-color: $color-bg-ix;
  opacity: 0.5;
  cursor: not-allowed;
}

/* ---------------------------------------------------
    TOGGLE
----------------------------------------------------- */

.toggle {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 32px;

  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + .slider {
      background-color: $color-brand-primary;

      &:before {
        background-color: ix-color('ix-white');
      }
    }

    &:checked + .slider:before {
      transform: translateX(16px);
    }
  }

  .slider {
    @extend .border-light;
    background-color: transparent;
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s;

    &:before {
      background-color: ix-color('gray-300');
      border-radius: 50%;
      bottom: 1px;
      content: '';
      height: 12px;
      left: 1px;
      position: absolute;
      transition: 0.2s;
      width: 12px;
    }
  }
}

/* ---------------------------------------------------
    Radio Button
----------------------------------------------------- */

.radio-button {
  .checkbox {
    margin-bottom: $gutter-sm;
  }
}

/* ---------------------------------------------------
    Textarea
----------------------------------------------------- */

textarea {
  resize: none;

  &.--large {
    min-height: 100px;
  }

  @include media-breakpoint-down(xs) {
    min-height: 100px;
    &.--large {
      min-height: calc(100px * 2);
    }
  }
}

textarea:disabled {
  background-color: $color-bg-ix;
  color: $color-txt-sec-no-focus;
  opacity: 0.5;
  cursor: not-allowed;
}
