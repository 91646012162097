/* -----------------------------------
  Export options selection
-------------------------------------*/

.export-select-options {
  @extend .border-dark;
  border-radius: ix-prop('br');
  min-height: calc(#{$modal-height-lg} - 200px);
  padding: $button-pad-side;

  p {
    margin: $gutter-sm 0;
  }

  // First Options //
  .options-1 {
    .checkbox-img {
      margin: $gutter-sm 0;
    }
  }

  // Second Options //
  .options-2 {
    border-left: 1px solid $color-borders-dark;
    padding-left: $gutter-sm * 2;

    .round {
      font-size: $font-size-14;
      margin: $gutter 0;
    }

    @include media-breakpoint-down(md) {
      border-left: none;
      border-top: 1px solid $color-borders-dark;
      flex: 0 0 100%;
      margin-top: $gutter;
      max-height: 100%;
      padding: $gutter-sm;
    }
  }
}
