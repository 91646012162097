/* -------------------------------------------
    Date Picker Input Style
--------------------------------------------*/

.react-date-picker {
  // Visual Wrapper //
  .react-date-picker__wrapper {
    @extend .border-light;
    @extend .bg-ix-white;
    border-radius: ix-prop('br');
    border: 1px solid $color-borders;
    min-width: 159px;
    max-width: 159px;
  }

  // Our Input style fix //
  input[type='number'] {
    background: transparent;
    border-radius: ix-prop('br');
    border: none !important;
    color: $color-txt-primary;
    height: 30px;
    min-height: 30px;
    padding: 0 12px 0 12px;

    &::placeholder {
      color: $color-txt-primary;
    }
  }

  // Remove focus in each input
  .box-shadow,
  input[type='date']:focus,
  input[type='text']:focus,
  input[type='number']:focus,
  .select:focus {
    box-shadow: none;
  }

  // Hover for input wrapper
  .react-date-picker__wrapper:hover {
    border: 1px solid ix-color('gray-500');
  }

  // Remove focus //
  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }
  .react-date-picker input[type='number']:focus {
    background: rgba(0, 0, 0, 0.03);
  }
  ::-moz-selection {
    background: rgba(24, 187, 128, 0.2);
  }
  ::selection {
    background: rgba(24, 187, 128, 0.2);
  }

  // "/" divider color //
  .react-date-picker__inputGroup__divider {
    color: $color-txt-primary;
  }

  // '0' span remove //
  span.react-date-picker__inputGroup__leadingZero {
    display: none;
  }
}

// Prevent input wrap
.react-date-picker__inputGroup {
  white-space: nowrap;
  overflow: hidden;

  .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year,
  .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
  .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day {
    box-sizing: content-box !important;
  }
}

// NO Zero
input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day.react-date-picker__inputGroup__input--hasLeadingZero {
  padding-left: 20px;
}
input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero {
  padding-left: 20px;
}

// Hovers
button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month:hover {
  background-color: $color-brand-hover !important;
}

button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year:hover {
  background-color: $color-brand-hover !important;
}

// mobile //
.react-date-picker.react-date-picker--closed.react-date-picker--enabled {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.react-date-picker.react-date-picker--open.react-date-picker--enabled {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.react-date-picker .react-date-picker__wrapper {
  @include media-breakpoint-down(md) {
    min-width: 100%;
  }
}

/* -------------------------------------------
    Calendar Style
--------------------------------------------*/

// Calendar itself //
.react-date-picker__calendar {
  inset: 40px 10% auto 0 !important;

  .react-calendar {
    @extend .border-light;
    background: ix-color('ix-white');
    border-radius: ix-prop('br');
    border-width: thin;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-family: 'oxygen-regular';
    padding: calc($gutter/2);

    // Weekend dates //
    .react-calendar__month-view__days__day--weekend {
      color: inherit;
    }

    // Selected //
    .react-calendar__tile--active {
      background: $color-brand-primary;
      border-radius: ix-prop('br');
      color: ix-color('ix-white') !important;
    }
    .react-calendar__tile--hasActive {
      background: $color-brand-primary !important;
      border-radius: ix-prop('br');
      color: ix-color('ix-white') !important;
    }
    // Today //
    .react-calendar__tile--now {
      background: ix-color('ix-white');
      color: $color-txt-interactive;
      font-family: 'oxygen-bold';
      // when today & selectd //
      &.react-calendar__tile--active {
        background: $color-brand-primary;
        border-radius: ix-prop('br');
        color: ix-color('ix-white');
      }
      &.react-calendar__tile--hasActive {
        background: $color-brand-primary;
        border-radius: ix-prop('br');
        color: ix-color('ix-white');
      }
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ix-color('gray-200');
      border-radius: ix-prop('br');
    }

    // Week days labels //
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      text-decoration: none;
    }

    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__month-view__weekdays {
      font-family: 'oxygen-bold';
    }

    // Move between months  //
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: ix-color('gray-200');
      border-radius: ix-prop('br');
    }

    // Desible Decades button //
    .react-calendar__navigation button[disabled] {
      background-color: initial;
    }

    // Selected Hovers //
    button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day:hover {
      background-color: $color-brand-hover !important;
      color: ix-color('ix-white') !important;
    }

    .button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month:hover {
      background-color: $color-brand-hover !important;
      color: ix-color('ix-white') !important;
    }
  }
}
